import { useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'

import { SurveysComponentProps } from '../SurveysComponent/SurveysComponent'
import { QUESTIONS_PER_PAGE } from 'constants/survey'
import { t } from 'i18n'
import { isQuestionAnswered, arePendingQuestions } from 'utils/survey'

export default function SurveyPagination(props: SurveysComponentProps) {
  const {
    page,
    setPage,
    surveyQuestions,
    surveyState,
    paginateQuestions,
    sendAnswersToAPI,
    finishSurvey,
    setDialogReward,
    preview,
  } = props

  const [nextPageErrorText, setNextPageErrorText] = useState('')

  const disableNextPageAction = () => {
    const questionNotAnsweredInPage = !!surveyQuestions
      ?.filter((question: Record<any, unknown>, index: number) =>
        paginateQuestions(index),
      )
      .find(question => !isQuestionAnswered(question))

    if (
      (questionNotAnsweredInPage && !preview) ||
      page >= +(surveyQuestions?.length / QUESTIONS_PER_PAGE)
    ) {
      return true
    }

    return false
  }

  const handleNextPageClick = () => {
    if (!disableNextPageAction()) {
      if (page < surveyQuestions.length) {
        setNextPageErrorText('')
        !preview && sendAnswersToAPI()
        handleSetPage(page + 1)
      }
    } else {
      setNextPageErrorText(t('ratingSection.unansweredItem'))
    }
  }

  // This manually created smooth scrolling is required to be compatible with any browser without native support
  const smoothScrollToTop = () => {
    const startPosition = window.scrollY
    const duration = 300
    const startTime = performance.now()

    const animateScroll = (currentTime: number) => {
      const elapsedTime = currentTime - startTime
      const progress = Math.min(elapsedTime / duration, 1)
      const easeInOutQuad = progress < 0.5
        ? 2 * progress * progress
        : -1 + (4 - 2 * progress) * progress
      const newPosition = startPosition * (1 - easeInOutQuad)

      window.scrollTo(0, newPosition)

      if (progress < 1) {
        requestAnimationFrame(animateScroll)
      }
    }

    requestAnimationFrame(animateScroll)
  }


  const handleSetPage = (value: number) => {
    setPage(value)
    smoothScrollToTop()
  }

  return (
    <Grid container justifyContent='center'>
      <Box m={2} sx={{ zIndex: 2 }}>
        <Button
          data-cy='previous_page'
          variant='outlined'
          color='primary'
          onClick={() => (page >= 1 ? handleSetPage(page - 1) : {})}
          disabled={page === 1}
          size='large'
        >
          {t('surveyLanding.surveyPagination.previous')}
        </Button>
      </Box>
      <Box m={2}>
        {arePendingQuestions(surveyQuestions) ||
          page < +(surveyQuestions?.length / QUESTIONS_PER_PAGE) ? (
          <Button
            data-cy='next_page'
            variant='contained'
            color='primary'
            onClick={handleNextPageClick}
            size='large'
            style={{ minWidth: 140 }}
            disabled={
              preview && page >= +(surveyQuestions.length / QUESTIONS_PER_PAGE)
            }
          >
            {t('surveyLanding.surveyPagination.nextPage')}
          </Button>
        ) : (
          !preview && (
            <Button
              data-cy='send_answers'
              color='primary'
              variant='contained'
              size='large'
              onClick={() => {
                if (surveyState.allow_reward && !surveyState.reward_intention) {
                  setDialogReward({ isOpen: true, data: null })
                }

                sendAnswersToAPI()
                finishSurvey()
              }}
            >
              {t('surveyLanding.submitSurvey')}
            </Button>
          )
        )}
      </Box>

      <Grid item xs={12}>
        {disableNextPageAction() && (
          <Typography
            data-cy='disable_next_page_action'
            variant='body2'
            color='error.light'
            align='center'
          >
            {nextPageErrorText}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}
