import { ReactNode, FC, useState } from 'react'
import { Box, Button, Modal, Backdrop, Fade, Typography, useMediaQuery, useTheme } from '@mui/material'
import FlareIcon from '@mui/icons-material/Flare'
import { DialogCloseButton } from 'components'
import { t } from 'i18n'

type FloatingButtonProps = {
  modalContent: ReactNode
}

const FloatingButton: FC<FloatingButtonProps> = ({ modalContent }) => {
  const theme: any = useTheme()
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)

  const isMiddleScreen = useMediaQuery(theme.breakpoints.up('sm'))

  if (isMiddleScreen && open) {
    setOpen(false)
  }

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          zIndex: 1000,
        }}
      >
        <Button
          onClick={handleOpen}
          sx={{
            background: theme =>
              `linear-gradient(90deg, ${theme.palette.warning.main} 0%, ${theme.palette.secondary.dark} 100%)`,
            color: 'white',
            width: 56,
            height: 56,
            borderRadius: '50%',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            minWidth: 0,
            padding: 0,
            '&:hover': {
              backgroundColor: 'primary.light',
              boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.15)',
            },
          }}
        >
          <FlareIcon fontSize="large" />
        </Button>
      </Box>

      <Modal
        open={open}
        onClose={handleDialogClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          sx: { backdropFilter: 'blur(5px)', backgroundColor: 'rgba(0, 0, 0, 0.5)' },
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              borderRadius: 4,
              p: 4,
              width: '90%',
              maxWidth: 500,
              maxHeight: '90%',
              overflowY: 'scroll',
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': { display: 'none' },
            }}
          >
            <DialogCloseButton handleClose={handleDialogClose} />

            <Typography variant='h5' color='text.primary' paragraph>
              {t('mobile.feed.floatingButton.dialogTitle')}
            </Typography>

            <Box sx={{ mt: 3 }}>{modalContent}</Box>
          </Box>
        </Fade>
      </Modal>
    </>
  )
}

export default FloatingButton
