import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import { isEmpty } from 'lodash'

import { NotificationItem } from '../../components'
import { t } from 'i18n'

export default function NotificationsComponent(props) {
  const {
    notifications,
    notificationsAreLoading,
    markAllNotificationsAsReadMutation,
    notificationsMenuState
  } = props

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper elevation={4} style={{ width: '100%' }}>
          <Box p={2} width={1}>
            <Grid container justifyContent='space-between' alignItems='center'>
              <Typography variant='h6' fontWeight={600} color='text.primary'>
                {t('notifications.yourNotifications')}
              </Typography>

              {notifications.find(notification => !notification.read) && (
                <Button
                  color='primary'
                  size='small'
                  onClick={markAllNotificationsAsReadMutation.mutate}
                >
                  {t('notifications.markAllRead')}
                </Button>
              )}
            </Grid>
          </Box>

          <Grid container style={{ maxHeight: 560, overflowY: 'scroll' }}>
            {notificationsAreLoading ? (
              <Grid
                container
                justifyContent='center'
                alignItems='center'
                sx={{ mt: 3, mb: 5 }}
              >
                <CircularProgress size={40} />
              </Grid>
            ) : isEmpty(notifications) ? (
              <Box p={2} pt={0}>
                <Typography variant='body2' color='text.secondary' paragraph>
                  {t('notifications.noNotifications')}
                </Typography>
              </Box>
            ) : (
              notifications.map(notification => (
                <NotificationItem
                  {...props}
                  key={notification.id}
                  notification={notification}
                  notificationsMenuState={notificationsMenuState}
                />
              ))
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
