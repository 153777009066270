import { useState } from 'react'

import {
  Box,
  Grid,
  Link,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  SentimentVeryDissatisfied as SentimentVeryDissatisfiedIcon,
  SentimentDissatisfied as SentimentDissatisfiedIcon,
  SentimentSatisfied as SentimentSatisfiedIcon,
  SentimentSatisfiedAlt as SentimentSatisfiedAltIcon,
  SentimentVerySatisfied as SentimentVerySatisfiedIcon,
  Star as StarIcon,
} from '@mui/icons-material'
import { Rating } from '@mui/material'
import { useIntl } from 'react-intl'

import { PulseSurveyQuestionType } from 'enums/pulseSurvey'
import { t } from 'i18n'

const renderIcon = Icon => <Icon style={{ fontSize: 40, margin: '0px 8px' }} />

const satisfactionIcons = {
  1: SentimentVeryDissatisfiedIcon,
  2: SentimentDissatisfiedIcon,
  3: SentimentSatisfiedIcon,
  4: SentimentSatisfiedAltIcon,
  5: SentimentVerySatisfiedIcon,
}

const ratingLabels = {
  1: t('ratingSection.ratingLabels.stronglyDisagree'),
  2: t('ratingSection.ratingLabels.disagree'),
  3: t('ratingSection.ratingLabels.neutral'),
  4: t('ratingSection.ratingLabels.agree'),
  5: t('ratingSection.ratingLabels.stronglyAgree'),
}

const useStyles = makeStyles(theme => ({
  satisfactionIcon: {
    color: theme.palette.primary.main,
  },
  importanceIcon: {
    color: theme.palette.secondary.main,
  },
}))

function SatisfactionIcons(props) {
  const { value, ...other } = props
  return <span {...other}>{renderIcon(satisfactionIcons[value])}</span>
}

function ImportanceIcons(props) {
  const { ...other } = props
  return <span {...other}>{renderIcon(StarIcon)}</span>
}

export default function PulseSurveyQuestionItem({
  index,
  question,
  answer,
  answers,
  setAnswers,
}) {
  const intl = useIntl()
  const classes = useStyles()
  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('md'),
    {
      defaultMatches: true,
    },
  )

  const [ratingHover, setRatingHover] = useState<number>(0)

  const SelectedSatisfactionIcon =
    answer?.satisfaction && satisfactionIcons[answer.satisfaction]

  const isCurrentQuestion = question => {
    const currentQuestion = answers.find(
      answer =>
        !answer.open_ended_text &&
        (!answer?.satisfaction || answer?.importance === null),
    )

    if (currentQuestion) return currentQuestion.question_id === question.id
    return false
  }

  const handleRatingAnswer = (value, ratingType) => {
    answer[ratingType] = value

    setAnswers([...answers])
  }

  const handleChangeAnswer = (answer, accessor) => {
    answer[accessor] = null

    setAnswers([...answers])
  }

  const renderRating = (question, ratingType) => (
    <Grid container direction='column'>
      {isCurrentQuestion(question) && (
        <Grid item xs={12}>
          <Typography
            variant='body2'
            color='text.secondary'
            fontWeight={500}
            align={isMobile ? 'center' : 'right'}
            paragraph
          >
            {ratingType === 'importance'
              ? t('ratingSection.important')
              : t('ratingSection.toWhatExtent')}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <Grid container justifyContent='flex-end' data-cy='rating_section'>
          <Grid item>
            <Grid container direction='column'>
              <Rating
                key={`${question.id}-${ratingType === 'importance' ? 'importance' : 'satisfaction'
                  }`}
                classes={{
                  iconFilled:
                    ratingType === 'importance'
                      ? classes.importanceIcon
                      : classes.satisfactionIcon,
                }}
                name={`rating-${question.id}-${ratingType === 'importance' ? 'importance' : 'satisfaction'
                  }`}
                IconContainerComponent={
                  ratingType === 'importance'
                    ? ImportanceIcons
                    : SatisfactionIcons
                }
                onChange={(event, value) => {
                  handleRatingAnswer(value, ratingType)
                }}
                onChangeActive={(event, newHover) => {
                  setRatingHover(newHover)
                }}
              />

              {isCurrentQuestion(question) && ratingHover > 0 && (
                <Grid item xs={12}>
                  <Grid container justifyContent='center'>
                    <Tooltip open title={ratingLabels[ratingHover]}>
                      <div></div>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

  const renderAnsweredQuestionIcon = (Icon, answer, accessor) => (
    <Grid item xs='auto' style={{ marginRight: 16, width: 120 }}>
      <Grid
        container
        direction='column'
        justifyContent='center'
        style={{ textAlign: 'center' }}
      >
        <Grid item>{Icon}</Grid>
        <Typography variant='body2' color='text.secondary'>
          {ratingLabels[answer[accessor]]}
        </Typography>
        <Link
          component='button'
          onClick={() => handleChangeAnswer(answer, accessor)}
        >
          {t('pulseSurvey.changeButtonText')}
        </Link>
      </Grid>
    </Grid>
  )

  return (
    <Box mb={3} key={question.id}>
      <Grid
        container
        spacing={2}
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item xs={12} sm>
          <Box my={2}>
            <Typography variant='body2' color='text.primary' fontWeight={600}>
              {index + 1}. {question.statement}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm='auto'>
          <Grid container justifyContent='flex-end' alignItems='center'>
            {question.type === PulseSurveyQuestionType.OpenEnded ||
              question.type === PulseSurveyQuestionType.OpenEndedAndImportance ? (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item>
                    <TextField
                      data-cy='openEndedQuestion'
                      multiline
                      minRows={3}
                      maxRows={8}
                      id={question.statement + index}
                      name={question.statement + index}
                      value={answer.open_ended_text}
                      label={intl.formatMessage({
                        id: 'pulseSurvey.openEndedLabel',
                      })}
                      placeholder={intl.formatMessage({
                        id: 'pulseSurvey.openEndedPlaceholder',
                      })}
                      onChange={event => {
                        answer.open_ended_text = event.target.value

                        setAnswers([...answers])
                      }}
                      variant='outlined'
                      style={{
                        width: '100%',
                        minWidth: 260,
                        maxWidth: 320,
                      }}
                      helperText={t('pulseSurvey.openEndedHelperText')}
                    />
                  </Grid>

                  {question.type ===
                    PulseSurveyQuestionType.OpenEndedAndImportance && (
                      <>
                        {answer.importance &&
                          renderAnsweredQuestionIcon(
                            <StarIcon color='secondary' />,
                            answer,
                            'importance',
                          )}

                        <Grid item xs={12} sm>
                          {!answer.importance &&
                            renderRating(question, 'importance')}
                        </Grid>
                      </>
                    )}
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Grid container>
                  {answer?.satisfaction &&
                    renderAnsweredQuestionIcon(
                      <SelectedSatisfactionIcon color='primary' />,
                      answer,
                      'satisfaction',
                    )}

                  {answer?.importance &&
                    renderAnsweredQuestionIcon(
                      <StarIcon color='secondary' />,
                      answer,
                      'importance',
                    )}

                  <Grid item xs={12} sm>
                    {(!answer?.satisfaction ||
                      (question.type ===
                        PulseSurveyQuestionType.SatisfactionAndImportance &&
                        !answer?.importance)) &&
                      renderRating(
                        question,
                        answer?.satisfaction ? 'importance' : 'satisfaction',
                      )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
