import { useState, useRef } from 'react'

import { useTheme } from '@mui/material'
import { cyan } from '@mui/material/colors'
import {
  Edit as EditIcon,
  LockOutlined as PasswordIcon,
  MailOutline as EmailIcon,
  People as EmployeesIcon,
  PersonAdd as EmployeeManagementIcon,
  PersonOutline as SpoofUserIcon,
  SystemUpdateAlt as HRISSectionIcon,
} from '@mui/icons-material'
import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { FaFileExcel as BulkUploadIcon } from 'react-icons/fa'
import { useIntl } from 'react-intl'
import { useMutation } from 'react-query'

import { useFetchEmployees } from './Employees.queries'
import { EmployeesChangePassword, EmployeesEmails } from './components'
import { CompanyAdminLayout, DialogTeleport } from 'components'
import { initialDialog } from 'constants/dialog'
import { useUser } from 'contexts'
import { API } from 'core/requests'
import { UserPermission } from 'enums/user'
import { t } from 'i18n'
import { EmployeesUpload, HRISImport, ManageEmployee } from 'pages'
import { useSpoofUser } from 'utils/spoof'
// import { format } from 'date-fns'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

export default function Employees(props) {
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useUser()!
  const { spoof } = useSpoofUser()
  const intl = useIntl()
  const tableRef = useRef();

  const [dialogAddEmployee, setDialogAddEmployee] = useState<any>({
    ...initialDialog,
    bulkMode: false,
  })
  const [dialogBulkUpload, setDialogBulkUpload] = useState(false)
  const [dialogHRISImport, setDialogHRISImport] = useState(false)
  const [dialogEmails, setDialogEmails] = useState<any>(initialDialog)
  const [dialogChangePassword, setDialogChangePassword] =
    useState<any>(initialDialog)

  const { refetch: fetchEmployees } = useFetchEmployees()

  const changePasswordMutation = useMutation(
    (payload: any) => API.update('changePasswordByAdmin', payload),
    {
      onSettled: (response: any) => {
        if (response && response.success) {
          enqueueSnackbar(response.message, {
            variant: 'success',
          })
          setDialogChangePassword(initialDialog)
        }
      },
    },
  )

  const resendEmailToEmployeeMutation = useMutation(
    (payload: any) => API.create('resendInvitationEmail', payload),
    {
      onSettled: (response: any) => {
        if (response && response.success) {
          enqueueSnackbar(t('employees.openedSurveyResent'), {
            variant: 'success',
          })
          setDialogEmails(initialDialog)
          fetchEmployees()
        }
      },
    },
  )

  const resendPulseSurveyEmailToEmployeeMutation = useMutation(
    (payload: any) => API.create('resendPulseSurveyInvitationEmail', payload),
    {
      onSettled: (response: any) => {
        if (response && response.success) {
          enqueueSnackbar(t('employees.openedSurveyResent'), {
            variant: 'success',
          })
          setDialogEmails(initialDialog)
          fetchEmployees()
        }
      },
    },
  )

  const sendBetaTeamInviteMutation = useMutation(
    (payload: any) => API.create('sendBetaTeamInviteEmail', payload),
    {
      onSettled: (response: any) => {
        if (response && response.success) {
          enqueueSnackbar(t('employees.betaTeamInviteSent'), {
            variant: 'success',
          })
          setDialogEmails(initialDialog)
          fetchEmployees()
        }
      },
    },
  )

  const sendWelcomeEmailToEmployeeMutation = useMutation(
    (payload: any) => API.create('sendWelcomeEmailToEmployeeEmail', payload),
    {
      onSettled: (response: any) => {
        if (response && response.success) {
          enqueueSnackbar(t('employees.welcomeEmailSent'), {
            variant: 'success',
          })
          setDialogEmails(initialDialog)
          fetchEmployees()
        }
      },
    },
  )

  const MAP_ENDPOINT_TO_FUNCTION = {
    resendEmailToEmployee: resendEmailToEmployeeMutation.mutate,
    resendPulseSurveyEmailToEmployee: resendPulseSurveyEmailToEmployeeMutation.mutate,
    sendBetaTeamInvite: sendBetaTeamInviteMutation.mutate,
    sendWelcomeEmailToEmployee: sendWelcomeEmailToEmployeeMutation.mutate,
  }

  const handleSendEmail = (endpoint, payload) => {
    MAP_ENDPOINT_TO_FUNCTION[endpoint](payload)
  }

  const theme: any = useTheme()

  return (
    <>
      <CompanyAdminLayout
        slug='employees'
        title={intl.formatMessage({ id: 'employees.title' })}
        description={t('employees.description')}
        color={cyan[900]}
        requestUrl='employees'
        tableProps={{
          tableRef: tableRef,
          columns: [
            {
              title: 'ID',
              field: 'id',
              editable: 'never',
              hidden: true,
            },
            {
              title: intl.formatMessage({ id: 'name' }),
              field: 'full_name',
              editable: 'never',
            },
            {
              title: intl.formatMessage({ id: 'employees.fields.email' }),
              field: 'email',
              editable: 'never',
            },
            {
              title: intl.formatMessage({ id: 'employees.fields.roles' }),
              field: 'roles',
              editable: 'never',
              render: (rowData: any) => rowData?.roles?.join(', '),
            },
            {
              title: intl.formatMessage({ id: 'employees.fields.title' }),
              field: 'title',
              editable: 'never',
            },
            {
              title: intl.formatMessage({ id: 'employees.fields.hireDate' }),
              field: 'hired_at',
              type: 'date',
              editable: 'never',
              render: (rowData: any) => (rowData?.hired_at) ? dayjs(rowData?.hired_at).format("MM/DD/YYYY") : "",
            },
            {
              title: intl.formatMessage({ id: 'employees.fields.dateOfBirth' }),
              field: 'date_of_birth',
              type: 'date',
              editable: 'never',
              hidden: true,
              render: (rowData: any) => (rowData?.date_of_birth) ? dayjs(rowData?.date_of_birth).format("MM/DD") : "",
            },
             {
              title: intl.formatMessage({ id: 'employees.fields.location' }),
              field: 'demographics',
              editable: 'never',
              hidden: true,
              render: (rowData: any) => {
                const location = rowData?.demographics?.find(
                  (item) => item.type === 'location'
                );
                return location?.value
              },
            },
            {
              title: intl.formatMessage({ id: 'employees.fields.region' }),
              field: 'demographics.region',
              editable: 'never',
              hidden: true,
              render: (rowData: any) => {
                const region = rowData?.demographics?.find(
                  (item) => item.type === 'region'
                );
                return region?.value
              },
            },
            {
              title: intl.formatMessage({ id: 'employees.fields.territory' }),
              field: 'demographics.Territory',
              editable: 'never',
              hidden: true,
              render: (rowData: any) => {
                const territory = rowData?.demographics?.find(
                  (item) => item.type === 'territory'
                );
                return territory?.value
              },
            },
            {
              title: intl.formatMessage({ id: 'employees.fields.department' }),
              field: 'demographics.Department',
              editable: 'never',
              hidden: true,
              render: (rowData: any) => {
                const department = rowData?.demographics?.find(
                  (item) => item.type === 'department'
                );
                return department?.value
              },
            },
            {
              title: intl.formatMessage({ id: 'employees.fields.businessUnit' }),
              field: 'demographics.Business Unit',
              editable: 'never',
              hidden: true,
              render: (rowData: any) => {
                const businessUnit = rowData?.demographics?.find(
                  (item) => item.type === 'business_unit'
                );
                return businessUnit?.value
              },
            },
            {
              title: intl.formatMessage({ id: 'employees.fields.supervisors' }),
              field: 'superiors',
              editable: 'never',
              hidden: true,
              render: (rowData: any) => { 
                return rowData.superiors.map((supervisor)=> {return   supervisor.superior.first_name + ' ' +supervisor.superior.last_name}).join(', ')},
            },
          ],
          options: {
            pageSizeOptions: [5, 10, 20, 50],
          },
          ...((user.permissions ?? []).includes(
            UserPermission.ManageEmployees,
          ) && {
            actions: [
              ...((user.permissions ?? []).includes(UserPermission.SendEmails)
                ? [
                    {
                      icon: () => <EmailIcon data-cy='sendEmails' />,
                      onClick: (_, row) =>
                        setDialogEmails({ isOpen: true, data: row }),
                      tooltip: () =>
                        intl.formatMessage({ id: 'employees.sendEmails' }),
                    },
                  ]
                : []),
              ...(user.sparck_administrator
                ? [
                    {
                      icon: () => <PasswordIcon />,
                      onClick: (_, row) =>
                        setDialogChangePassword({ isOpen: true, data: row }),
                      tooltip: () =>
                        intl.formatMessage({
                          id: 'employees.tooltips.changePassword',
                        }),
                    },
                    {
                      icon: () => <SpoofUserIcon />,
                      onClick: (_, row) => spoof({ id: row.id }),
                      tooltip: () =>
                        intl.formatMessage({
                          id: 'employees.tooltips.spoofEmployee',
                        }),
                    },
                  ]
                : []),
              {
                isFreeAction: true,
                icon: () => <EditIcon color='primary' />,
                onClick: () =>
                  setDialogAddEmployee({
                    isOpen: true,
                    data: null,
                    bulkMode: true,
                  }),
                tooltip: () =>
                  intl.formatMessage({ id: 'employees.bulkEditEmployees' }),
              },
            ],
          }),
        }}
        customHeaderActions={
          [
            // {
            //   label: 'Employees Graph',
            //   onClick: () => setDialogEmployeesGraph(true),
            // },
          ]
        }
        customActions={[
          {
            title: intl.formatMessage({ id: 'employees.hrisSection' }),
            actionLabel: intl.formatMessage({
              id: 'employees.hrisSectionLabel',
            }),
            description: intl.formatMessage({
              id: 'employees.hrisSectionDescription',
            }),
            onClick: () => setDialogHRISImport(true),
            Icon: HRISSectionIcon,
            color: theme.palette.secondary.main,
          },
          {
            title: intl.formatMessage({ id: 'employees.bulkUpload' }),
            actionLabel: intl.formatMessage({
              id: 'employees.bulkUploadLabel',
            }),
            description: intl.formatMessage({
              id: 'employees.bulkUploadDescription',
            }),
            onClick: () => setDialogBulkUpload(true),
            Icon: BulkUploadIcon,
            color: theme.palette.error.main,
          },
          ...((user.permissions ?? []).includes(UserPermission.ManageEmployees)
            ? [
                {
                  title: intl.formatMessage({
                    id: 'employees.employeeManagement',
                  }),
                  actionLabel: intl.formatMessage({
                    id: 'employees.employeeManagementLabel',
                  }),
                  description: intl.formatMessage({
                    id: 'employees.employeeManagementDescription',
                  }),
                  onClick: () =>
                    setDialogAddEmployee({
                      isOpen: true,
                      data: null,
                      bulkMode: false,
                    }),
                  Icon: EmployeeManagementIcon,
                  color: theme.palette.primary.main,
                },
              ]
            : []),
        ]}
        managementPermission={UserPermission.ManageEmployees}
        ManagementForm={ManageEmployee}
        managementFormProps={{
          tableRef: tableRef,
        }}
        IconHeader={EmployeesIcon}
        disableCreate
      />

      <DialogTeleport
        dialogTitle=''
        dialogAction={null}
        dialogSize='sm'
        dialogOpen={dialogHRISImport}
        handleDialogClose={() => setDialogHRISImport(false)}
        isFullWidth
      >
        <HRISImport
          closeDialog={() => setDialogHRISImport(false)}
          fetchEmployees={fetchEmployees}
        />
      </DialogTeleport>

      <DialogTeleport
        dialogTitle=''
        dialogAction={null}
        dialogSize='lg'
        dialogOpen={dialogBulkUpload}
        handleDialogClose={() => setDialogBulkUpload(false)}
        isFullWidth
      >
        <EmployeesUpload
          {...props}
          dialogBulkUpload={dialogBulkUpload}
          setDialogBulkUpload={setDialogBulkUpload}
          fetchEmployees={fetchEmployees}
        />
      </DialogTeleport>

      <DialogTeleport
        dialogTitle=''
        dialogAction={null}
        dialogSize='md'
        dialogOpen={dialogAddEmployee.isOpen}
        handleDialogClose={() => setDialogAddEmployee(initialDialog)}
        isFullWidth
      >
        <ManageEmployee
          {...props}
          dialog={dialogAddEmployee}
          closeDialog={() => setDialogAddEmployee(initialDialog)}
          fetchRows={fetchEmployees}
          bulkMode={dialogAddEmployee.bulkMode}
        />
      </DialogTeleport>

      <DialogTeleport
        dialogTitle={t('employees.sendEmailsTo', {
          name: get(dialogEmails, 'data.first_name'),
        })}
        dialogAction={null}
        dialogSize='sm'
        dialogOpen={dialogEmails.isOpen}
        handleDialogClose={() => setDialogEmails(initialDialog)}
        isFullWidth
      >
        <EmployeesEmails
          employee={dialogEmails.data}
          handleSendEmail={handleSendEmail}
        />
      </DialogTeleport>

      {user.sparck_administrator && (
        <DialogTeleport
          dialogTitle={t('employees.changeEmployeePasswordDialog.title')}
          dialogAction={null}
          dialogSize='xs'
          dialogOpen={dialogChangePassword.isOpen}
          handleDialogClose={() => setDialogChangePassword(initialDialog)}
          isFullWidth
        >
          <EmployeesChangePassword
            employee={dialogChangePassword.data}
            changePasswordMutation={changePasswordMutation}
          />
        </DialogTeleport>
      )}
    </>
  )
}