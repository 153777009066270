import React from 'react'

import { Box, Button, Grid, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import { UseMutationResult } from 'react-query'
import { FetchOptions } from 'react-query/types/core/query'
import { useHistory } from 'react-router'

import { FeedAction, FeedPosts, FeedValues, FeedFloatingButton } from '../../components'
import { ConfirmationDialog } from 'components'
import { ROUTES_PATHS } from 'core/routes'
import { UserData } from '../FeedPost/FeedPost'
import { t } from 'i18n'
import { DialogData, PostMedia, PostType } from 'types'

type Props = {
  id: string
  posts: PostType[]
  feedPostsConfirmDelete: DialogData
  setFeedPostsConfirmDelete: React.Dispatch<React.SetStateAction<any>>
  deletePostMutation: UseMutationResult
  deleteCommentMutation: UseMutationResult
  suggestionState: {
    description: string
    anonymous: boolean
  }
  setSuggestionState: React.Dispatch<
    React.SetStateAction<{
      description: string
      anonymous: boolean
    }>
  >
  likePostStatus: any
  publishSuggestion: any
  publishSuggestionStatus: any
  company: any
  coreValues: any
  coreValuesIsLoading: boolean
  recognitions: any
  recognitionsIsLoading: any
  nominationsPrograms: any
  createNominationMutation: UseMutationResult
  employees: any
  postId: any
  hasMorePosts: boolean
  NUMBER_OF_POSTS_PER_PAGE: number
  postState: any
  setPostState: React.Dispatch<React.SetStateAction<any>>
  publishPost: (post: PostType) => void
  publishPostStatus: UseMutationResult
  uploadPostMediaStatus: UseMutationResult
  postsIsLoading: boolean
  publishComment: (any) => void
  publishCommentStatus: UseMutationResult
  likePost: (any) => void
  user: UserData
  fetchPosts: (options?: FetchOptions | undefined) => any
  setPostsState: React.Dispatch<React.SetStateAction<PostType[]>>
  postMedia: PostMedia
  setPostMedia: React.Dispatch<React.SetStateAction<PostMedia>>
}

export default function FeedComponent(props: Props) {
  const intl = useIntl()

  const {
    posts,
    deletePostMutation,
    feedPostsConfirmDelete,
    setFeedPostsConfirmDelete,
    postId,
    hasMorePosts,
    NUMBER_OF_POSTS_PER_PAGE,
  } = props

  const history = useHistory()

  const feedValuesComponent = <FeedValues {...props} />

  return (
    <Box width={1} marginTop={2}>
      <Grid container justifyContent='center'>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <Grid container>
                <Grid item xs={12}>
                  <FeedAction {...props} />
                </Grid>

                <Grid sx={{ display: { xs: 'block', sm: 'none' } }}>
                  <FeedFloatingButton modalContent={feedValuesComponent} />
                </Grid>

                <Grid item xs={12}>
                  <FeedPosts {...props} />
                </Grid>

                {postId && posts.length > 0 && (
                  <Grid item xs={12}>
                    <Box my={2} width={1}>
                      <Button
                        variant='contained'
                        color='primary'
                        size='large'
                        fullWidth
                        onClick={() => history.push(ROUTES_PATHS.feed)}
                      >
                        {t('feed.loadAllPosts')}
                      </Button>
                    </Box>
                  </Grid>
                )}

                {!postId &&
                  !hasMorePosts &&
                  posts.length > NUMBER_OF_POSTS_PER_PAGE * 2 && (
                    <Box mt={3} width={1}>
                      <Typography
                        align='center'
                        variant='body2'
                        color='text.secondary'
                        paragraph
                      >
                        {t('feed.endOfPosts')} =)
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4} sx={{ display: { xs: 'none', sm: 'block', lg: 'block' } }}>
              {feedValuesComponent}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ConfirmationDialog
        dialogOpen={feedPostsConfirmDelete.isOpen}
        setDialogOpen={setFeedPostsConfirmDelete}
        message={intl.formatMessage(
          { id: 'components.confirmationDialog.defaultMessage' },
          {
            action: intl.formatMessage({
              id: 'components.confirmationDialog.delete',
            }),
            item: intl.formatMessage({
              id: 'feed.thisPost',
            }),
          },
        )}
        item={feedPostsConfirmDelete?.data}
        actionProps={{
          onClick: () =>
            deletePostMutation.mutate(feedPostsConfirmDelete?.data),
          isLoading: deletePostMutation.isLoading,
          label: intl.formatMessage({
            id: 'components.confirmationDialog.delete',
          }),
          color: 'error',
        }}
      />
    </Box>
  )
}
