import { useEffect } from 'react'

import { get } from 'lodash'
import { useMutation, useQuery } from 'react-query'

import { NotificationsComponent } from './components'
import { useUser } from 'contexts'
import { API } from 'core/requests'
import { ROUTES_PATHS } from 'core/routes'
import { MentionNotificationSource, NotifiableType } from 'enums/notification'
import { UserRole } from 'enums/user'
import { t } from 'i18n'
import { getUserFullName } from 'utils/user'
import { PopupState } from 'material-ui-popup-state/core'

interface NotificationsProps {
  fetchBadgesCount: () => void;
  notificationsMenuState: PopupState
}

export default function Notifications({ fetchBadgesCount, notificationsMenuState }: NotificationsProps) {
  const { user } = useUser()!

  const fetchNotificationsAndBadgesCount = () => {
    fetchNotifications()
    if (fetchBadgesCount) fetchBadgesCount()
  }

  const {
    data: notifications,
    isLoading: notificationsAreLoading,
    refetch: fetchNotifications,
  } = useQuery('getNotifications', () => API.get('notifications'), {
    enabled: false,
  })

  const markNotificationAsReadMutation = useMutation(
    (payload: any) => API.update('markNotificationAsRead', payload),
    {
      onSuccess: (response: any) => {
        if (response) {
          fetchNotificationsAndBadgesCount()
        }
      },
    },
  )

  const markAllNotificationsAsReadMutation = useMutation(
    () => API.update('markAllNotificationsAsRead'),
    {
      onSuccess: (response: any) => {
        if (response) {
          fetchNotificationsAndBadgesCount()
        }
      },
    },
  )

  const deletePostMutation = useMutation(
    (payload: any) => API.delete('notifications', payload),
    {
      onSuccess: (response: any) => {
        if (response.success) {
          fetchNotificationsAndBadgesCount()
        }
      },
    },
  )

  useEffect(() => {
    fetchNotifications()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getSurveyNotificationContent = notification => {
    const surveyId = get(notification, 'notifiable.content.id')
    const surveyName = get(notification, 'notifiable.content.name')

    const getViewSurveyPath = surveyId =>
      user.sparck_administrator ||
        [UserRole.CompanyAdmin].includes(get(user, 'profile'))
        ? `${ROUTES_PATHS.surveyOverview}?id=${surveyId}`
        : ROUTES_PATHS.surveyLanding

    return {
      ask_participant_to_take_beheard_survey: {
        title: t('notifications.survey.isNowOpened', {
          surveyName: <b>{surveyName}</b>,
        }),
        path: getViewSurveyPath(surveyId),
        actionLabel: t('notifications.actionLabels.viewSurvey'),
      },
      notify_participant_that_beheard_survey_has_been_closed: {
        title: t('notifications.survey.isClosed', {
          surveyName: <b>{surveyName}</b>,
        }),
        path: getViewSurveyPath(surveyId),
        actionLabel: t('notifications.actionLabels.viewSurvey'),
        date: 'close_at',
      },
      ask_participant_to_finish_reopened_beheard_survey: {
        title: t('notifications.survey.isReopened', {
          surveyName: <b>{surveyName}</b>,
        }),
        path: getViewSurveyPath(surveyId),
        actionLabel: t('notifications.actionLabels.viewSurvey'),
        date: 'last_reopen_at',
      },
      ask_participant_to_finish_extended_beheard_survey: {
        title: t('notifications.survey.wasExtended', {
          surveyName: <b>{surveyName}</b>,
        }),
        path: getViewSurveyPath(surveyId),
        actionLabel: t('notifications.actionLabels.viewSurvey'),
        date: 'last_reopen_at',
      },
      suggest_company_admin_to_get_beheard_premium_report: {
        title: t('notifications.survey.canNowBeUpgraded', {
          surveyName: <b>{surveyName}</b>,
        }),
        path: getViewSurveyPath(surveyId),
        actionLabel: t('notifications.actionLabels.viewSurvey'),
        date: 'close_at',
      },
      ask_participant_to_finish_beheard_survey: {
        title: t('notifications.survey.needsToBeFinished', {
          surveyName: <b>{surveyName}</b>,
        }),
        path: getViewSurveyPath(surveyId),
        actionLabel: t('notifications.actionLabels.viewSurvey'),
        date: 'close_at',
      },
      warn_participant_to_finish_beheard_survey_today: {
        title: t('notifications.survey.lastDayToFinish', {
          surveyName: <b>{surveyName}</b>,
        }),
        path: getViewSurveyPath(surveyId),
        actionLabel: t('notifications.actionLabels.viewSurvey'),
        date: 'close_at',
      },
      notify_company_admin_that_beheard_premium_report_is_available: {
        title: t('notifications.survey.fullReportAvailable', {
          surveyName: <b>{surveyName}</b>,
        }),
        path: `${ROUTES_PATHS.beheardReport}?id=${surveyId}&full_report=1`,
        actionLabel: t('notifications.actionLabels.viewReport'),
      },
      thank_participant_for_finishing_beheard_survey: {
        title: t('notifications.survey.thankYou', {
          surveyName: <b>{surveyName}</b>,
        }),
        path: getViewSurveyPath(surveyId),
        actionLabel: t('notifications.actionLabels.viewSurvey'),
      },
      notify_company_admin_that_beheard_free_report_is_available: {
        title: t('notifications.survey.freeReportAvailable', {
          surveyName: <b>{surveyName}</b>,
        }),
        path: `${ROUTES_PATHS.beheardReport}?id=${surveyId}&full_report=0`,
        actionLabel: t('notifications.actionLabels.viewReport'),
      },
    }
  }

  const getPulseSurveyNotificationContent = notification => {
    const pulseSurveyId = get(notification, 'notifiable.content.id')
    const pulseSurveyName = get(notification, 'notifiable.content.name')

    const getViewPulseSurveyPath = pulseSurveyId =>
      user.sparck_administrator ||
        [UserRole.CompanyAdmin].includes(get(user, 'profile'))
        ? `${ROUTES_PATHS.pulseSurveyOverview}/${pulseSurveyId}`
        : ROUTES_PATHS.pulseSurveys

    return {
      ask_participant_to_take_pulse_survey: {
        title: t('notifications.pulseSurvey.isNowOpened', {
          pulseSurveyName: <b>{pulseSurveyName}</b>,
        }),
        path: getViewPulseSurveyPath(pulseSurveyId),
        actionLabel: t('notifications.actionLabels.viewPulseSurvey'),
      },
      notify_participant_that_pulse_survey_has_been_closed: {
        title: t('notifications.pulseSurvey.isClosed', {
          pulseSurveyName: <b>{pulseSurveyName}</b>,
        }),
        path: getViewPulseSurveyPath(pulseSurveyId),
        actionLabel: t('notifications.actionLabels.viewPulseSurvey'),
        date: 'close_at',
      },
      ask_participant_to_finish_reopened_pulse_survey: {
        title: t('notifications.pulseSurvey.isReopened', {
          pulseSurveyName: <b>{pulseSurveyName}</b>,
        }),
        path: getViewPulseSurveyPath(pulseSurveyId),
        actionLabel: t('notifications.actionLabels.viewPulseSurvey'),
        date: 'last_reopen_at',
      },
      ask_participant_to_finish_extended_pulse_survey: {
        title: t('notifications.pulseSurvey.wasExtended', {
          pulseSurveyName: <b>{pulseSurveyName}</b>,
        }),
        path: getViewPulseSurveyPath(pulseSurveyId),
        actionLabel: t('notifications.actionLabels.viewPulseSurvey'),
        date: 'last_reopen_at',
      },
      ask_participant_to_finish_pulse_survey: {
        title: t('notifications.pulseSurvey.needsToBeFinished', {
          pulseSurveyName: <b>{pulseSurveyName}</b>,
        }),
        path: getViewPulseSurveyPath(pulseSurveyId),
        actionLabel: t('notifications.actionLabels.viewPulseSurvey'),
      },
      warn_participant_to_finish_pulse_survey_today: {
        title: t('notifications.pulseSurvey.lastDayToFinish', {
          pulseSurveyName: <b>{pulseSurveyName}</b>,
        }),
        path: getViewPulseSurveyPath(pulseSurveyId),
        actionLabel: t('notifications.actionLabels.viewPulseSurvey'),
      },
      thank_participant_for_finishing_pulse_survey: {
        title: t('notifications.pulseSurvey.thankYou', {
          pulseSurveyName: <b>{pulseSurveyName}</b>,
        }),
        path: getViewPulseSurveyPath(pulseSurveyId),
        actionLabel: t('notifications.actionLabels.viewPulseSurvey'),
      },
    }
  }

  const getSparckNotificationContent = notification => {
    const sparckId = get(notification, 'notifiable.content.id')
    const sparckOwner = get(
      notification,
      'notifiable.content.user_recognizing',
      {},
    )
    const sparckedUser = (notification.notifiable?.content?.users_recognized ??
      [])[0]
    const sparckAnswer = get(notification, 'notifiable.content.answer')

    return {
      notify_recognizer_that_their_beseen_recognition_has_been_sent: {
        avatar: get(sparckedUser, 'avatar.url'),
        title: t('notifications.sparck.youRecognized', {
          sparckedUser: <b>{sparckedUser?.full_name}</b>,
        }),
        path: `${ROUTES_PATHS.sparck}/${sparckId}`,
        actionLabel: t('notifications.actionLabels.viewSparck'),
      },
      notify_recognized_that_they_received_a_beseen_recognition: {
        avatar: get(sparckOwner, 'avatar.url'),
        title: t('notifications.sparck.recognizedBy', {
          sparckOwner: <b>{sparckOwner.name}</b>,
        }),
        path: `${ROUTES_PATHS.sparck}/${sparckId}`,
        actionLabel: t('notifications.actionLabels.viewSparck'),
      },
      warn_meaningful_user_to_send_beseen_recognition: {
        avatar: get(sparckedUser, 'avatar.url'),
        title: t('notifications.sparck.takeActionForRecognition', {
          sparckOwner: <b>{sparckOwner.name}</b>,
          sparckedUser: <b>{sparckedUser?.full_name}</b>,
        }),
        path: `${ROUTES_PATHS.takeAction}`,
        actionLabel: t('notifications.actionLabels.takeAction'),
      },
      notify_recognizer_that_their_beseen_recognition_has_been_answered: {
        avatar: get(sparckedUser, 'avatar.url'),
        title: t('notifications.sparck.wasAnswered', {
          sparckedUser: <b>{sparckedUser?.full_name}</b>,
        }),
        description: sparckAnswer,
        path: `${ROUTES_PATHS.sparck}/${sparckId}`,
        actionLabel: t('notifications.actionLabels.viewSparck'),
      },
      notify_manager_of_reward_redeemed: {
        avatar: get(sparckedUser, 'avatar.url'),
        title: t('notifications.sparck.rewardRedeemed', {
          sparckedUser: <b>{sparckedUser?.full_name}</b>,
        }),
        description: sparckAnswer,
        path: `${ROUTES_PATHS.sparck}/${sparckId}`,
        actionLabel: t('notifications.actionLabels.viewSparck'),
      },
    }
  }

  const getMentionNotificationContent = notification => {
    const sourceId = get(notification, 'notifiable.content.source_id')

    const mentioningUser = getUserFullName(
      get(notification, 'notifiable.content.user_mentioning', {}),
    )

    const sourceType = get(notification, 'notifiable.content.source_type')

    const commentPostId = notification?.notifiable?.content?.source?.post_id

    const getSourceTypeName = source_type => {
      switch (source_type) {
        case MentionNotificationSource.Post:
          return 'Post'
        case MentionNotificationSource.Sparck:
          return 'Sparck'
        case MentionNotificationSource.Comment:
          return 'Comment'
        default:
          return
      }
    }

    const getSourceTypeRoutePath = source_type => {
      switch (source_type) {
        case MentionNotificationSource.Post:
          return `${ROUTES_PATHS.feed}/${sourceId}`
        case MentionNotificationSource.Sparck:
          return `${ROUTES_PATHS.sparck}/${sourceId}`
        case MentionNotificationSource.Comment:
          return `${ROUTES_PATHS.feed}/${commentPostId}?commentId=${sourceId}`
        default:
          return
      }
    }

    return {
      warn_user_that_they_have_been_mentioned: {
        title: t('notifications.mentions.title', {
          mentioningUser: <b>{mentioningUser}</b>,
          sourceType: getSourceTypeName(sourceType),
        }),
        path: getSourceTypeRoutePath(sourceType),
        actionLabel: t(
          `notifications.actionLabels.view${getSourceTypeName(sourceType)}`,
        ),
      },
    }
  }

  const getSuggestionBoxIdeaNotificationContent = notification => {
    return {
      warn_user_of_reply_added_to_suggestion_box_idea: {
        title: t('notifications.suggestions.newReply.title', {
          newReply: (
            <b>{t('notifications.suggestions.newReply.newReplyLabel')}</b>
          ),
        }),
        path: `${ROUTES_PATHS.suggestionChat}/${notification?.notifiable?.content?.id}`,
        actionLabel: t('notifications.actionLabels.goToChat'),
      },

      warn_company_admin_of_new_suggestion_box_idea: {
        title: t('notifications.suggestions.newSuggestion.title'),
        path: ROUTES_PATHS.suggestions,
        actionLabel: t('notifications.actionLabels.goToSuggestions'),
      },
    }
  }

  const getNominationNotificationContent = notification => {
    return {
      warn_users_that_they_have_a_nomination_open: {
        title: (
          <>
            Nomination <b>{notification?.notifiable?.content?.name}</b> is open!
          </>
        ),
        path: ROUTES_PATHS.feed,
        actionLabel: 'Go to Feed',
      },
      warn_users_that_the_event_is_closed_now: {
        title: (
          <>
            Nomination <b>{notification?.notifiable?.content?.name}</b> is
            closed!
          </>
        ),
        path: ROUTES_PATHS.feed,
        actionLabel: 'Go to Feed',
      },
      notify_winners_of_nomination_event: {
        title: (
          <>
            You won Nomination <b>{notification?.notifiable?.content?.name}</b>.
            Congratulations!
          </>
        ),
        // path: ROUTES_PATHS.suggestions,
        // actionLabel: t('notifications.actionLabels.goToSuggestions'),
      },
    }
  }

  const getGeneralNotificationContent = notification => {
    return {
      notify_company_admin_to_refill_funds: {
        title: (
          <>
            <b>{notification?.notifiable?.content?.message}</b>
          </>
        ),
        path: ROUTES_PATHS.budget,
        actionLabel: 'Go to Budget',
      },
    }
  }

  const getUserNotificationContent = notification => {
    const recipient = get(
      notification,
      'notifiable.content.rewarded_user',
      {},
    )

    const sender = get(
      notification,
      'notifiable.content.rewarding_user',
      {},
    )

    return {
      alert_manager_about_rewarded_user_exceeded_budget_limit: {
        title: t('notifications.sparck.userExceededBudgetLimit', {
          title: (
            <b>Reward Update:</b>
          ),
          recipient: (
            <b>{(`${recipient?.first_name} ${recipient?.last_name}`).trim()}</b>
          ),
          sender: (
            <b>{(`${sender?.first_name} ${sender?.last_name}`).trim()}</b>
          ),
        }),
        path: ROUTES_PATHS.budget,
        actionLabel: 'Go to Budget',
      },
      alert_admin_about_rewarding_user_exceeded_budget_limit: {
        title: t('notifications.sparck.managerExceededBudgetLimit', {
          title: (
            <b>Reward Update:</b>
          ),
          recipient: (
            <b>{(`${recipient?.first_name} ${recipient?.last_name}`).trim()}</b>
          ),
          sender: (
            <b>{(`${sender?.first_name} ${sender?.last_name}`).trim()}</b>
          ),
        }),
        path: ROUTES_PATHS.budget,
        actionLabel: 'Go to Budget',
      },
    }
  }

  const getNotificationData = notification => {
    if (get(notification, 'notifiable.type') === NotifiableType.Survey) {
      return {
        ...getSurveyNotificationContent(notification)[notification.type],
      }
    }

    if (get(notification, 'notifiable.type') === NotifiableType.PulseSurvey) {
      return {
        ...getPulseSurveyNotificationContent(notification)[notification.type],
      }
    }

    if (get(notification, 'notifiable.type') === NotifiableType.Sparck) {
      return {
        ...getSparckNotificationContent(notification)[notification.type],
      }
    }

    if (get(notification, 'notifiable.type') === NotifiableType.Mention) {
      return {
        ...getMentionNotificationContent(notification)[notification.type],
      }
    }

    if (
      [NotifiableType.NominationAward, NotifiableType.NominationEvent].includes(
        get(notification, 'notifiable.type'),
      )
    ) {
      return {
        ...getNominationNotificationContent(notification)[notification.type],
      }
    }

    if (
      get(notification, 'notifiable.type') === NotifiableType.SuggestionBoxIdea
    ) {
      return {
        ...getSuggestionBoxIdeaNotificationContent(notification)[
        notification.type
        ],
      }
    }

    if (
      get(notification, 'notifiable.type') === NotifiableType.GeneralNotification
    ) {
      return {
        ...getGeneralNotificationContent(notification)[
        notification.type
        ],
      }
    }

    if (
      get(notification, 'notifiable.type') === NotifiableType.User
    ) {
      return {
        ...getUserNotificationContent(notification)[
        notification.type
        ],
      }
    }

    return {
      title: '',
      description: '',
      path: ROUTES_PATHS.dashboard,
      actionLabel: '',
    }
  }

  return (
    <NotificationsComponent
      notifications={notifications ?? []}
      notificationsAreLoading={notificationsAreLoading}
      markNotificationAsReadMutation={markNotificationAsReadMutation}
      markAllNotificationsAsReadMutation={markAllNotificationsAsReadMutation}
      deletePostMutation={deletePostMutation}
      getNotificationData={getNotificationData}
      notificationsMenuState={notificationsMenuState}
    />
  )
}
