import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'
import SaveIcon from '@mui/icons-material/Save'
import { Skeleton } from '@mui/material'
import { get } from 'lodash'
import { useHistory } from 'react-router-dom'

import sparckLogoWhite from 'assets/images/sparck-logo-white.png'
import { UserAvatar } from 'components'
import { useUser } from 'contexts'
import { ROUTES_PATHS } from 'core/routes'
import { UserRole } from 'enums/user'
import { t } from 'i18n'

export default function ProfileHeader({
  profileState,
  editMode,
  setEditMode,
  setDialogChangePicture,
  userIsLoading,
  editUser,
  editUserStatus,
  userCanEdit,
  isProfileFromLoggedUser,
}) {
  const theme = useTheme()
  const history = useHistory()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const { user } = useUser()!

  const PROFILE_DIMENSION = isMobile ? 96 : 160

  return (
    <Grid container style={{ backgroundColor: theme.palette.primary.main }}>
      <Grid item xs={12}>
        <Box position='relative' width={1}>
          {userCanEdit && (
            <Button
              data-cy='profile_header_button'
              variant='outlined'
              sx={{
                position: 'absolute',
                right: 16,
                top: 16,
                minWidth: isMobile ? 'auto' : '64px',
                '&.MuiButton-outlined': {
                  color: 'white',
                  borderColor: 'white',
                },
              }}
              size='small'
              onClick={() => {
                if (editMode) {
                  editUser({
                    ...profileState,
                    pronoun:
                      profileState.pronoun === 'custom'
                        ? profileState.customPronoun
                        : profileState.pronoun,
                  })
                }
                setEditMode(!editMode)
              }}
            >
              {editUserStatus.isLoading ? (
                <CircularProgress size={24} style={{ color: '#FFF' }} />
              ) : editMode ? (
                isMobile ? (
                  <SaveIcon />
                ) : (
                  t('actions.save', { name: '' })
                )
              ) : isMobile ? (
                <EditIcon />
              ) : (
                t('actions.edit', { name: '' })
              )}
            </Button>
          )}
          <Grid
            container
            justifyContent='center'
            style={{ position: 'relative' }}
          >
            <Paper
              elevation={4}
              sx={{
                borderRadius: '100%',
                width: PROFILE_DIMENSION,
                height: PROFILE_DIMENSION,
                position: 'absolute',
                top: `-${PROFILE_DIMENSION / 2}px`,
              }}
            >
              <UserAvatar
                userId={get(profileState, 'id')}
                name={`${profileState.first_name} ${profileState.last_name}`}
                src={get(profileState, 'avatar.url')}
                size={PROFILE_DIMENSION}
                sx={{
                  position: 'relative',
                  objectFit: 'cover',
                }}
              />

              {userCanEdit && (
                <IconButton
                  sx={{
                    borderRadius: '100%',
                    position: 'absolute',
                    bottom: 8,
                    right: -8,
                    backgroundColor: 'secondary.dark',
                    ':hover': {
                      backgroundColor: 'secondary.main',
                    },
                  }}
                  onClick={() => setDialogChangePicture(true)}
                  size='large'
                >
                  <EditIcon
                    style={{ color: '#FFF' }}
                    data-cy='profile_avatar_edit_button'
                  />
                </IconButton>
              )}
            </Paper>
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant={isMobile ? 'h5' : 'h3'}
          color='text.white'
          align='center'
          style={{ marginTop: PROFILE_DIMENSION - PROFILE_DIMENSION * 0.4 }}
          fontWeight='bold'
          sx={{ mb: 1 }}
        >
          {userIsLoading ? (
            <Grid container justifyContent='center' component='span'>
              <Skeleton width={200} />
            </Grid>
          ) : (
            profileState.first_name &&
            `${profileState.first_name} ${profileState.last_name}`
          )}
          {profileState.pronoun && profileState.pronoun !== 'custom' && (
            <Typography
              component='span'
              variant='body1'
              color='text.grey'
              fontWeight='500'
              paragraph
            >{` (${profileState.pronoun})`}</Typography>
          )}
        </Typography>
      </Grid>

      {!isProfileFromLoggedUser && !userIsLoading && (
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Grid container justifyContent='center'>
            <Grid item xs='auto'>
              <Button
                variant='outlined'
                size='large'
                onClick={() => {
                  history.push(
                    `${user.sparck_administrator ||
                      [UserRole.CompanyAdmin].some(role =>
                        user.roles.includes(role),
                      )
                      ? ROUTES_PATHS.sparck
                      : ROUTES_PATHS.feed
                    }?user_to_recognize_id=${profileState.id}`,
                  )
                }}
                sx={{
                  '&.MuiButton-outlined': {
                    color: 'text.white',
                    borderColor: 'text.white',
                  },
                }}
              >
                <Grid container spacing={2} alignItems='center'>
                  <Grid item xs='auto'>
                    Recognize me
                  </Grid>

                  <Grid item xs='auto'>
                    <Grid container alignItems='center'>
                      <img
                        src={sparckLogoWhite}
                        alt='Sparck logo white'
                        style={{ height: 24, width: 24 }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
