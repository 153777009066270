export default {
  actions: {
    create: 'Criar {name}',
    edit: 'Editar {name}',
    save: 'Salvar {name}',
  },
  routes: {
    approvals: 'Aprovações',
    beheardReport: 'Relatório #BeHeard',
    beheardSurvey: 'Pesquisa #BeHeard',
    budget: 'Orçamentos',
    changePassword: 'Alterar Senha',
    companies: 'Organizações',
    companyInsights: 'Métricas da Organização',
    companyReporting: 'Relatório Organizacional',
    coreValues: 'Valores Fundamentais',
    dashboard: 'Dashboard',
    demographics: 'Demografia',
    employees: 'Funcionários',
    feed: 'Feed',
    inappropriateContent: 'Conteúdo Impróprio',
    insights: 'Métricas',
    invoices: 'Faturas',
    login: 'Entrar',
    logout: 'Sair',
    marketing: 'Upgrade',
    managePulseSurvey: 'Gerenciar Pesquisa Pulse',
    managerCertification: 'Certificação de Funcionários',
    managerCertificationTask:
      'Tarefa da Certificação de Reconhecimento de Funcionários',
    nominationsManagement: 'Nomeações',
    pulseSurveyInsights: 'Insights',
    pulseSurveyOverview: 'Pesquisas Pulse',
    manageSurvey: ' ',
    surveyOverview: 'Pesquisas #BeHeard',
    company: 'Minha Organização',
    myRewards: 'Minhas Recompensas',
    mySettings: 'Minhas Configurações',
    notifications: 'Notificações',
    onboarding: 'Introdução',
    onboardingQuestions: 'Perguntas de Introdução',
    people: 'Pessoas',
    rewards: 'Benefícios',
    profile: 'Perfil',
    profileQuestions: 'Perguntas de Perfil',
    pulseSurveys: 'Pesquisas Pulse',
    recognitionPrograms: 'Eventos de Reconhecimento',
    roles: 'Responsabilidades',
    settings: 'Configurações',
    shareReport: 'Compartilhar Relatório',
    shoppingCart: 'Carrinho de Compras',
    signup: 'Criar Conta',
    recognize: 'Reconheça!',
    sparckDisplay: 'Mostrar Sparck',
    sparckMemories: 'Memórias Sparck',
    suggestionChat: 'Chat da Sugestão',
    suggestions: 'Sugestões',
    survey: 'Pesquisa',
    surveys: 'Pesquisas',
    takeAction: 'Tomar Ação',
    teamInsights: 'Métricas do Time',
    virginExperiencesSettings: 'Virgin Experiences',
  },
  industries: {
    accounting: 'Contabilidade',
    airlinesAviation: 'Companhias Aéreas/Aviação',
    alternativeDisputeResolution: 'Resolução Alternativa de Disputas',
    alternativeMedicine: 'Medicina Alternativa',
    animation: 'Animação',
    apparelFashion: 'Vestuário e Moda',
    architecturePlanning: 'Arquitetura e Planejamento',
    artsCrafts: 'Artes e Ofícios',
    automotive: 'Automotivo',
    aviationAerospace: 'Aviação e Aeroespacial',
    banking: 'Bancário',
    biotechnology: 'Biotecnologia',
    broadcastMedia: 'Broadcast Media',
    buildingMaterials: 'Materiais de Construção',
    businessSuppliesEquipment: 'Suprimentos e Equipamentos Comerciais',
    cannabis: 'Cannabis',
    capitalMarkets: 'Mercados Capitais',
    chemicals: 'Produtos Químicos',
    civicSocialOrganization: 'Organização Cívica e Social',
    civilEngineering: 'Engenharia Civil',
    commercialRealEstate: 'Imóveis Comerciais',
    computerNetworkSecurity: 'Segurança de Computador e Rede',
    computerGames: 'Jogos de Computador',
    computerHardware: 'Hardware de Computador',
    computerNetworking: 'Rede de Computadores',
    computerSoftware: 'Software de Computador',
    construction: 'Construção',
    consumerElectronics: 'Eletrônicos de Consumo',
    consumerGoods: 'Bens de Consumo',
    consumerServices: 'Serviços do Consumidor',
    cosmetics: 'Cosméticos',
    dairy: 'Laticínio',
    defenseSpace: 'Defesa e Espaço',
    design: 'Projeto',
    eLearning: 'E-learning',
    educationManagement: 'Gerenciamento de Educação',
    electricalElectronicManufacturing: 'Manufatura Elétrica e Eletrônica',
    entertainment: 'Entretenimento',
    environmentalServices: 'Serviços Ambientais',
    eventServices: 'Serviços de Eventos',
    executiveOffice: 'Escritório Executivo',
    facilitiesServices: 'Serviços de Instalações',
    farming: 'Agricultura',
    financialServices: 'Serviços Financeiros',
    fineArt: 'Belas Artes',
    fishery: 'Pesca',
    foodBeverages: 'Alimentos e Bebidas',
    foodProduction: 'Produção de Alimentos',
    fundraising: 'Arrecadação',
    furniture: 'Mobiliário',
    gamblingCasinos: 'Jogos de Azar e Cassinos',
    glassCeramicsConcrete: 'Vidro, Cerâmica e Concreto',
    governmentAdministration: 'Administração Governamental',
    governmentRelations: 'Relações Governamentais',
    graphicDesign: 'Design Gráfico',
    healthWellnessFitness: 'Saúde, Bem-estar e Preparação Física',
    higherEducation: 'Ensino Superior',
    hospitalHealthCare: 'Hospital e Cuidados de Saúde',
    hospitality: 'Hospitalidade',
    humanResources: 'Recursos Humanos',
    importExport: 'Importar e Exportar',
    individualFamilyServices: 'Serviços Individuais e Familiares',
    industrialAutomation: 'Automação Industrial',
    informationServices: 'Serviços de Informação',
    informationTechnologyServices: 'Serviços de Informação e Tecnologia',
    insurance: 'Seguro',
    internationalAffairs: 'Assuntos Internacionais',
    internationalTradeDevelopment: 'Comércio Internacional e Desenvolvimento',
    internet: 'Internet',
    investmentManagement: 'Gestão de Investimentos',
    investmentBanking: 'Investimento Bancário',
    judiciary: 'Judiciário',
    lawEnforcement: 'Aplicação da Lei',
    lawPractice: 'Prática de Direito',
    legalServices: 'Serviços Legais',
    legislativeOffice: 'Gabinete Legislativo',
    leisureTravelTourism: 'Lazer, Viagem e Turismo',
    libraries: 'Bibliotecas',
    logisticsSupplyChain: 'Logística e Cadeia de Suprimentos',
    luxuryGoodsJewelry: 'Bens e Joias de Luxo',
    machinery: 'Maquinário',
    managementConsulting: 'Consultoria de Gestão',
    maritime: 'Marítimo',
    marketResearch: 'Pesquisa de Mercado',
    marketingAdvertising: 'Marketing e Publicidade',
    mechanicalOrIndustrialEngineering: 'Engenharia Mecânica ou Industrial',
    mediaProduction: 'Produção da Mídia',
    medicalDevice: 'Aparelho Médico',
    medicalPractice: 'Prática Médica',
    mentalHealthCare: 'Cuidados de Saúde Mental',
    military: 'Militares',
    miningMetals: 'Mineração e Metais',
    motionPicturesFilm: 'Filmes e Filmes',
    museumsInstitutions: 'Museus e Instituições',
    music: 'Música',
    nanotechnology: 'Nanotecnologia',
    newspapers: 'Jornais',
    nonProfitManagement: 'Gestão sem Fins Lucrativos',
    oilEnergy: 'Petróleo e Energia',
    onlineMedia: 'Mídia on-line',
    outsourcingOffshoring: 'Terceirização/Offshoring',
    packageFreightDelivery: 'Entrega de Pacote/Frete',
    packagingContainers: 'Embalagem e Recipientes',
    paperForestProducts: 'Papel e Produtos Florestais',
    performingArts: 'Artes Performáticas',
    pharmaceuticals: 'Farmacêutica',
    philanthropy: 'Filantropia',
    photography: 'Fotografia',
    plastics: 'Plásticos',
    politicalOrganization: 'Organização Política',
    primarySecondaryEducation: 'Educação Primária/Secundária',
    printing: 'Impressão',
    professionalTrainingCoaching: 'Treinamento e Coaching Profissional',
    programDevelopment: 'Desenvolvimento de Programa',
    publicPolicy: 'Políticas Públicas',
    publicRelationsCommunications: 'Relações Públicas e Comunicações',
    publicSafety: 'Segurança Pública',
    publishing: 'Publicação',
    railroadManufacturer: 'Fabricante de Ferrovias',
    ranching: 'Pecuária',
    realEstate: 'Imobiliária',
    recreationalFacilitiesServices: 'Instalações e Serviços Recreativos',
    religiousInstitutions: 'Instituições Religiosas',
    renewablesEnvironment: 'Renováveis ​​e Meio Ambiente',
    research: 'Pesquisar',
    restaurants: 'Restaurantes',
    retail: 'Varejo',
    securityInvestigations: 'Segurança e Investigações',
    semiconductors: 'Semicondutores',
    shipbuilding: 'Construção Naval',
    sportingGoods: 'Artigos Esportivos',
    staffingRecruiting: 'Pessoal e Recrutamento',
    supermarkets: 'Supermercados',
    telecommunications: 'Telecomunicações',
    textiles: 'Têxteis',
    thinkTanks: 'Think Tanks',
    tobacco: 'Tabaco',
    translationLocalization: 'Tradução e Localização',
    transportationTruckingRailroad: 'Transporte/Caminhão/Ferrovia',
    utilities: 'Serviços de Utilidade Pública',
    ventureCapitalPrivateEquity: 'Capital de Risco e Patrimônio Privado',
    veterinary: 'Veterinário',
    warehousing: 'Armazenagem',
    wholesale: 'Atacado',
    wineSpirits: 'Vinho e Bebidas Espirituosas',
    wireless: 'Wireless',
    writingEditing: 'Escrita e Edição',
  },
  topbar: {
    viewReleaseNotes: 'Ver Notas de Lançamento',
    termsOfService: 'Ver Termos de Serviço',
    privacyPolicy: 'Ver Política de Privacidade',
  },
  footer: { weLove: 'Nós', slogan: 'Reconhecimento único como você!' },
  formHandler: {
    create: 'Criar',
    edit: 'Editar',
    update: 'Atualizar',
    updated: 'atualizado com sucesso',
    created: 'criado(a) com sucesso',
    successWithTitle: ' ',
    success: 'Sucesso!',
  },
  confirmDelete: {
    confirmation: 'Deseja realmente',
    deleteLabel: 'excluir',
    delete: 'Excluir Item',
  },
  table: {
    body: {
      emptyDataSourceMessage: 'Sem linhas para mostrar',
      addTooltip: 'Adicionar',
      deleteTooltip: 'Remover',
      editTooltip: 'Editar',
      filterRow: { filterPlaceHolder: 'Filtrar', filterTooltip: 'Filtrar' },
      editRow: {
        deleteText: 'Tem certeza que deseja excluir esta linha?',
        cancelTooltip: 'Cancelar',
        saveTooltip: 'Salvar',
      },
    },
    grouping: {
      placeholder: 'Arrastar cabeçalhos...',
      groupedBy: 'Agrupado por:',
    },
    header: { actions: 'Ações' },
    pagination: {
      labelDisplayedRows: '{from}-{to} de {count}',
      labelRowsSelect: 'linhas',
      labelRowsPerPage: 'Linhas por página:',
      firstAriaLabel: 'Primeira Página',
      firstTooltip: 'Primeira Página',
      previousAriaLabel: 'Página Anterior',
      previousTooltip: 'Página Anterior',
      nextAriaLabel: 'Próxima Página',
      nextTooltip: 'Próxima Página',
      lastAriaLabel: 'Última Página',
      lastTooltip: 'Última Página',
    },
    toolbar: {
      addRemoveColumns: 'Adicionar ou remover colunas',
      nRowsSelected: '{0} linha(s) selecionada(s)',
      showColumnsTitle: 'Mostrar Colunas',
      showColumnsAriaLabel: 'Mostrar Colunas',
      exportTitle: 'Exportar',
      exportAriaLabel: 'Exportar',
      exportPDFName: 'Exportar em PDF',
      exportCSVName: 'Exportar em CSV',
      searchTooltip: 'Pesquisar',
      searchPlaceholder: 'Pesquisar',
    },
  },
  simpleCrudTable: {
    actions: 'Ações',
    limit: {
      firstLine: 'Você pode adicionar até',
      secondLine: 'itens apenas.',
    },
    rows: 'linhas',
  },
  simpleLibrarySelection: {
    selected: { singular: 'Selecionado(a)', plural: 'Selecionados(as)' },
    sortedAscending: 'ordem crescente',
    sortedDescending: 'ordem decrescente',
  },
  companyAdminLayout: {
    add: 'Adicionar',
    remove: 'Remover',
    thisItem: 'este item',
    successfullyUpdated: 'atualizado(a) com sucesso!',
    successfullyDeleted: 'excluído(a) com sucesso!',
    success: 'Sucesso!',
    all: 'Todos',
  },
  emails: {
    welcomeEmailCompany: {
      title: 'E-mail de Boas Vindas',
      description:
        'Envie este e-mail se a organização ainda não foi notificada sobre sua criação.',
    },
    welcomeEmailEmployee: {
      title: 'E-mail de Boas Vindas',
      description:
        'Envie este-email se você quer dar boas vindas a um funcionário em nossa plataforma',
    },
    welcomeEmailAllEmployees: {
      title: 'E-mail de Boas Vindas a todos os funcionários',
      description:
        'Envie este e-mail se você quer dar boas vindas a todos os funcionários de uma vez',
    },
    welcomeEmailNewEmployees: {
      title: 'E-mail de Boas Vindas aos novos funcionários',
      description:
        'Envie este e-mail se você quer dar boas vindas a apenas um funcionário que não tenha recebido o e-mail ainda',
    },
    betaTeamInviteEmail: {
      title: 'E-mail de Convite do Time Beta',
      description:
        'Envie este e-mail se você quer convidar um funcionário para o Sparck Beta',
    },
    resendSurvey: {
      title: 'E-mail para Reenviar Pesquisa',
      description:
        'Envie este e-mail se você quer convidar um funcionário para uma pesquisa novamnete',
    },
  },
  linkHere: 'AQUI',
  loading: 'Carregando...',
  yes: 'Sim',
  no: 'Não',
  thereAreNo: 'Não há {name}',
  name: 'Nome',
  nameIsRequired: 'Nome é obrigatório',
  description: 'Descrição',
  firstName: 'Nome',
  lastName: 'Sobrenome',
  phoneNumber: 'Telefone',
  cancel: 'Cancelar',
  department: 'Departamento',
  email: 'Email',
  status: 'Status',
  birthday: 'Aniversário',
  for: 'para',
  invalidDateFormat: 'Data Inválida',
  participation: 'Participação',
  reward: 'Benefício',
  type: 'Tipo',
  value: 'Valor',
  select: 'Selecionar',
  back: 'Voltar',
  statuses: {
    accepted: 'Aceito',
    approved: 'Aprovado',
    declined: 'Recusado',
    pending: 'Pendente',
  },
  beheardSurveyDrivers: {
    alignment: 'Alinhamento',
    team: 'Equipe',
    fit: 'Compatibilidade',
    valuing: 'Valorização',
    growth: 'Crescimento',
    turnover: 'Turnover',
  },
  dashboard: {
    welcome: {
      title: 'Bem-vindo(a) ao Sparck!',
      description:
        'Parabéns por configurar sua conta corporativa. Para começar, siga as etapas neste painel para lançar sua pesquisa gratuita de engajamento #BeHeard!',
    },
    getStarted: 'Começar',
    greetings: 'Feliz {day}, {userName}!',
    noReminders: 'Você não tem lembretes.',
    reminders: {
      year: 'ano',
      years: 'anos',
      workiversaryOn: '{userName} {years} de trabalho na {date}',
      birthdays: 'Aniversários',
      workiversaries: 'Aniversários de Trabalho',
      showToday: 'Mostrar somente {sectionName} de hoje',
      showUpcoming: 'Mostrar {sectionName} próximos',
      today: '{sectionName} de hoje',
      upcoming: '{sectionName} próximos',
      noRemindersToday: 'Não há {reminderSectionName} hoje',
    },
    profileProgress: 'O seu perfil está {percentage} completo.',
    tasks: 'Tarefas',
    noTasks: 'Você está em dia com suas tarefas!',
  },
  pulseSurvey: {
    header: 'Sua voz é importante',
    responsesAreAnonymous: 'Todas as respostas são anônimas.',
    needHelp: 'Need help?',
    clickHere: 'Click here',
    changeButtonText: 'Mudar',
    previous: 'Anterior',
    next: 'Próximo',
    finish: 'Concluir',
    openEndedLabel: 'Sua Resposta',
    openEndedPlaceholder: 'Digite sua resposta aqui...',
    openEndedHelperText:
      'Para garantir sua confidencialidade, não coloque nenhuma informação de identificação pessoal.',
    noPulseSurveys:
      'Não há nenhuma Pesquisa Pulse no momento, mas te avisaremos quando houver.',
    statistics: {
      tableHeaders: {
        satisfaction: 'Satisfação',
        importance: 'Importância',
        openEnded: 'Texto Aberto',
        statement: 'Afirmação',
        agree: 'concordam',
        neutral: 'neutros',
        disagree: 'discordam',
        important: 'importante',
        indifferent: 'indiferente',
        not_important: 'não importante',
      },
      answers: 'Respostas',
    },
  },
  pulseSurveyOverview: {
    header: 'Pesquisas Pulse',
    textFieldLabel: 'Pesquisa Pulse',
    snackbarSuccess: {
      openedPulseSurvey: 'A Pesquisa Pulse foi lançada!',
      closedPulseSurvey: 'A Pesquisa Pulse foi encerrada',
      extendedPulseSurvey: 'The Pulse Survey has been extended',
      deletedPulseSurvey: 'A Pesquisa Pulse foi excluída!',
      addedToCart: 'Pesquisa Pulse adicionada ao seu carrinho com sucesso',
    },
    newPulseSurvey: 'Nova Pesquisa Pulse',
    launchPulseSurvey: 'Lançar Pesquisa Pulse',
    editPulseSurvey: 'Editar Pesquisa Pulse',
    deletePulseSurvey: 'Excluir Pesquisa Pulse',
    closePulseSurvey: 'Encerrar Pesquisa Pulse',
    extendPulseSurvey: 'Extend Pulse Survey',
    pulseReport: 'Relatório Pulse',
    dialogs: {
      title: 'Pesquisas Pulse',
      create: {
        descriptionFirstLine:
          'É hora de tornar a Pesquisa Pulse sua. Você pode fazer mudanças antes do lançamento da Pesquisa Pulse e estender sua duração antes de ser encerrada. Clique',
        link: 'AQUI',
        descriptionSecondLine:
          'para melhores práticas relacionadas a Pesquisas Pulse.',
      },
      edit: {
        descriptionFirstLine:
          'Precisa editar sua Pesquisa Pulse? Sem problema. Você pode fazer isso aqui. Por favor, faça suas mudanças abaixo. Clique',
        link: 'AQUI',
        descriptionSecondLine:
          'para melhores práticas relacionadas a Pesquisas Pulse.',
      },
      confirmClose: {
        title: 'Encerrar Pesquisa Pulse',
        actionLabel: 'Encerrar Pesquisa Pulse',
        deleteItemLabel: 'encerrar',
      },
      confirmDelete: {
        title: 'Excluir Pesquisa Pulse',
        actionLabel: 'Excluir Pesquisa Pulse',
        alternateConfirmDeleteLabel:
          'Esta pesquisa pulse tem respostas associadas a ela. Tem certeza de que deseja excluí-la? Esta ação não poderá ser desfeita.',
      },
      demographicsPickerLabel:
        'Quais Grupos Demográficos receberão esta Pesquisa Pulse?',
      rolesPicker: {
        label:
          'Funcionários com quais responsabilidades receberão esta Pesquisa Pulse?',
        selectLabel: 'Responsabilidades',
      },
      formLabels: {
        pulseSurveyName: 'Nome da Pesquisa Pulse',
        launchDate: 'Data de Lançamento',
        closeDate: 'Data de Encerramento',
      },
      questionsTable: {
        header: 'Questões',
        tableHeaders: {
          statement: 'Afirmação',
          showImportance: 'Mostrar Escala de Importância?',
          isOpenEnded: 'É Questão Aberta?',
        },
        statementPlaceholder: 'Digite sua questão/afirmação...',
        statementHelperText:
          'Por favor, digite sua questão e clique no botão "+" para adicioná-la.',
        librarySelection: { headers: { statement: 'Afirmação' } },
      },
    },
    validations: {
      pulseSurveyNameIsRequired: 'Nome da Pesquisa Pulse é obrigatório',
      minimumLaunchDate: 'Você não pode selecionar uma data passada',
      maximumLaunchDate:
        'Data de Lançamento não pode ser depois da Data de Encerramento',
      launchDateIsRequired: 'Data de Lançamento é obrigatória',
      minimumCloseDate:
        'Date de Encerramento não pode ser anterior à data de Lançamento',
      closeDateIsRequired: 'Data de Encerramento é obrigatória',
    },
  },
  surveyLaunchMode: {
    label: 'How would you like to launch your survey?',
    manual: {
      label: 'Launch Manually',
      description:
        "By selecting Launch Manually, your survey won't launch until you click the {launchButtonLabel} button on the Manage Surveys screen. You will also be required to enter a Close Date before you are able to launch your survey which will automatically close the survey on the date you selected.",
    },
    scheduled: {
      label: 'Schedule Launch',
      description:
        'By selecting Schedule Launch, your survey will automatically launch on the launch date you specified and will automatically close on the close date specified. You can always launch your survey earlier by clicking the {launchButtonLabel} button from the Manage Survey screen.',
    },
    drivers: {
      label: 'Which engagement drivers you want to include',
      description: 'TODO By selecting {launchButtonLabel} button from the Manage Survey screen.',
      fit: 'Fit',
      fitToolTipText: 'Fit',
      growth: 'Growth',
      growthToolTipText: 'Growth',
      alignment: 'Alignment',
      alignmentToolTipText: 'Alignment',
      value: 'Value',
      valueToolTipText: 'Value',
      team: 'Team',
      teamToolTipText: 'Team',
    },
    changeManagerText: {
      label: 'Use this text in place of Manager',
      toolTipDescription:
        'You can write text like: Managers, Director so it will be shown in your survey page',
    },
  },
  myCompany: {
    greetings: { good: 'Feliz', welcomeBack: 'Bem-vindo de volta!' },
    companyOverview: {
      cardTitle: 'Visão Geral da Organização',
      sectionLabels: {
        name: 'Nome da Organização',
        numberOfEmployees: 'Número de Funcionários',
        companyAdministrators: 'Administradores da Organização',
      },
      sendBulkEmails: 'Enviar Emails em Massa',
      textFieldLabel: 'Nome da Organização',
      seeLess: 'Ver Menos',
      seeMore: 'Ver Mais',
      dialogTitle: 'Enviar e-mails',
    },
    companyBeheard: {
      cardTitle: 'Fazer Upgrade no Relatório',
      getMoreFromResults:
        'Aproveite melhor seus resultados. Saiba mais a respeito do Relatório #BeHeard Premium.',
      learnMore: 'Saiba Mais',
    },
    companyGotQuestions: {
      cardTitle: 'Alguma Pergunta?',
      weAreHereToHelp: 'Estamos aqui para te ajudar!',
      sectionLabels: {
        customerExperience: 'Parceiro(a) de Experiência do Cliente',
        technicalSupport: 'Suporte Técnico',
      },
    },
    companySurveyUpdates: {
      cardTitle: 'Atualizações de Pesquisas',
      emptyMessage: 'No momento não há nenhuma pesquisa.',
      seeAllSurveys: 'Ver todas as Pesquisas',
      columnLabels: {
        surveyType: 'Tipo da Pesquisa',
        openDate: 'Data de Lançamento',
        closeDate: 'Data de Encerramento',
        invites: 'Convites',
      },
      linkLabels: {
        manageBeheardSurvey: 'Gerenciar Pesquisa #BeHeard',
        beheardPremiumReport: 'Relatório #BeHeard Premium',
        shareReport: 'Compartilhar Relatório',
        upgradeToBeheardPremiumReport:
          'Fazer Upgrade para o Relatório #BeHeard Premium',
        beheardFreeReport: 'Relatório #BeHeard Gratuito',
      },
    },
    companyPulseSurveyUpdates: {
      cardTitle: 'Atualizações de Pesquisas Pulse',
      emptyMessage: 'No momento não há nenhuma Pesquisa Pulse.',
      seeAllPulseSurveys: 'Ver todas as Pesquisas Pulse',
      columnLabels: {
        openDate: 'Data de Lançamento',
        closeDate: 'Data de Encerramento',
      },
      linkLabels: { managePulseSurvey: 'Gerenciar Pesquisa Pulse' },
    },
    companySuggestionUpdates: {
      cardTitle: 'Atualizações de Sugestões',
      emptyMessage: 'No momento não há nenhuma sugestão.',
      seeAllSuggestions: 'Ver todas as Sugestões',
      columnLabels: {
        date: 'Data',
        from: 'De',
        numberOfReplies: 'Número de respostas',
        suggestion: 'Sugestão',
      },
    },
    companyFreeResources: {
      cardTitle: 'Recursos Gratuitos',
      content:
        'Conheça nosso kit de ferramentas com tudo que você precisará para ter uma experiência eficaz com a Pesquisa #BeHeard.',
      letsDoIt: 'Vamos Lá !',
    },
    companyCostAnalysis: {
      cardTitle: 'Análise de Custo',
      content:
        'Quanto está te custando a falta de engajamento de seus funcionários? Descubra com nossa Análise de Custo de Engajamento GRATUITA.',
      getStartedNow: 'Começar Agora!',
    },
    companyEmails: {
      dialogTitle: 'Tem certeza que deseja enviar',
      sendEmails: 'Enviar e-mails',
    },
    snackbarSuccess: {
      updated: 'Dados da Organização atualizados com sucesso',
      surveyAddedToCart: 'Pesquisa adicionada ao seu carrinho com sucesso',
    },
  },
  companies: {
    addFunds: 'Adicionar Créditos',
    allowApplication: 'Permitir aplicação {application}',
    allowInternationalization: 'Permitir Internacionalização',
    beheardAccess: 'Acesso #BeHeard',
    beseenAccess: 'Acesso #Beseen',
    clientSince: 'Cliente Desde',
    companyName: 'Nome da Organização',
    createCompany: 'Criar Organização',
    description:
      'Você, como o Administrador do Sparck, pode gerenciar organizações do jeito que preferir :)',
    editCompany: 'Editar Organização',
    emails: 'E-mails',
    funds: 'Créditos',
    fundsAmount: 'Quantidade de Créditos',
    manageNgcFor: 'Gerenciar NGC para',
    ngcAccountSuccessfullyUpdated: 'Conta NGC atualizada com sucesso!',
    ngcAmount: 'Quantidade do NGC',
    ngcApiClientId: 'NGC API Client ID',
    ngcApiSecret: 'Senha da API do NGC',
    ngcManagement: 'Gerenciamento do NGC',
    virginExperiencesManagement: 'Gerenciamento do Virgin Experiences',
    released: 'Lançado',
    saveChanges: 'Salvar Mudanças',
    sendEmailsTo: 'Enviar e-mails para',
    sparckAmount: 'Montante do Sparck',
    title: 'Organizações',
    total: 'Total',
    validations: { companyNameIsRequired: 'Nome da Organização é obrigatório' },
    snackbar: { success: { emailSent: 'Este email foi enviado com sucesso!' } },
  },
  employees: {
    title: 'Funcionários',
    description:
      'Bem-vindo! Para começar é rápido e fácil, basta fazer upload do endereço de email de seus funcionários. Você pode incluir informações demográficas sobre o ambiente de trabalho mais tarde se você fizer upgrade para o relatório #BeHeard Premium.',
    openedSurveyResent:
      'O e-mail de abertura de pesquisa foi reenviado para este usuário!',
    betaTeamInviteSent:
      'O e-mail de convite time Beta foi reenviado para este usuário!',
    welcomeEmailSent: 'O e-mail de boas vindas foi enviado para este usuário!',
    employeeRoleDisclaimer:
      'If you create an employee without adding a role they will get the Employee role by default. If the employee is a Manager or above, select the Role before creating the employee.',
    fields: {
      businessUnit: 'Unidade de Negócio',
      companyInternalId: 'ID Interno da Organização',
      dateOfBirth: 'Data de Nascimento',
      email: 'E-mail',
      ethnicity: 'Etnia',
      firstName: 'Nome',
      gender: 'Gênero',
      highValueTalent: 'Talento de Alto Valor',
      highValueTalentTooltip:
        'Filtre os relatórios por nível de desempenho com base em métricas internas (desempenho baixo, médio e alto, por exemplo).',
      hireDate: 'Data de Admissão',
      jobType: 'Tipo de Trabalho',
      location: 'Nome da Localização',
      performanceLevel: 'Nível de Performance',
      performanceLevelTooltip:
        'Filtre os relatórios por nível de desempenho com base em percepções internas (baixo, médio, alto, por exemplo).',
      region: 'Região',
      roles: 'Responsabilidades',
      supervisors: 'Supervisores',
      territory: 'Território',
      title: 'Cargo',
    },
    placeholders: { male: 'Masculino' },
    sendEmails: 'Enviar E-mails',
    tooltips: {
      changePassword: 'Mudar Senha',
      spoofEmployee: 'Entrar como este Usuário',
    },
    bulkEditEmployees: 'Editar Múltiplos Funcionários',
    hrisSection: 'Seção HRIS',
    hrisSectionLabel: 'Importar Funcionários',
    hrisSectionDescription:
      'Facilite ainda mais o upload de funcionários e faça a integração com seu sistema HRIS.',
    bulkUpload: 'Upload em Massa',
    bulkUploadLabel: 'Planilha Upload',
    bulkUploadDescription:
      '200 linhas? 500+ linhas? Nós podemos lidar com sua planilha de funcionários!',
    employeeManagement: 'Gerenciamento de Funcionário',
    employeeManagementLabel: 'Adicionar um Funcionário',
    employeeManagementDescription:
      'Quer adicionar seus funcionários individualmente? Você pode fazer isso aqui!',
    sendEmailsTo: 'Envie e-mails para {name}',
    changeEmployeePasswordDialog: {
      title: 'Mudar Senha do Funcionário',
      changePassword: 'Mudar Senha',
    },
    employeesGraphDialogTitle: 'Visualização do Gráfico dos Funcionários',
    selectEmployees: 'Selecionar Funcionários',
    manageEmployeeFormTabs: {
      info: 'Informações',
      demographics: 'Dados Demográficos',
      rolesAndSupervisors: 'Responsabilidades & Supervisores',
    },
    generalInformation: 'Informações Gerais',
    employeeContact: 'Contato do Funcionário',
    employeeLocation: 'Localização do Funcionário',
    demographicInformation: 'Informação Demográfica',
    validations: {
      invalidEmailFormat: 'Formato de email inválido',
      emailIsRequired: 'Email é obrigatório',
    },
    snackbars: {
      error: {
        assignAtLeastOneRole:
          'Por favor, atribua pelo menos uma responsabilidade ao funcionário.',
      },
    },
  },
  hrisImport: {
    trinet: 'TriNet',
    submittedHRISSystem:
      'Você enviou {hrisSuggestion} como seu sistema HRIS. Obrigado por nos informar.',
    employeesImported:
      'Seus funcionários foram importados com sucesso de seu HRIS!',
    importEmployeesFromHRIS:
      'Importe funcionários a partir do seu sistema HRIS',
    selectHRIS:
      'Selecione um sistema HRIS e insira a ID do seu sistema HRIS - é simples assim!',
    hrisSystem: 'Sistema HRIS',
    companyId: 'ID da Organização',
    importEmployeesLabel: 'Importe Funcionários pelo HRIS',
    doYouUseAnotherHRIS: 'Você usa outro sistema HRIS?',
    comingSoon:
      'Se você estiver usando um sistema HRIS diferente, avise-nos. Digite o nome abaixo e nós o priorizaremos para integrações futuras.',
    submit: 'Enviar',
    futureDevelopment:
      'Nós colocaremos isso na lista dos nossos desenvolvimentos futuros',
  },
  employeesUpload: {
    employeesImportedSuccessfully: 'Funcionários importados com sucesso!',
    uploadEmployeeInformation:
      'Faça upload das Informações dos seus Funcionários',
    description1:
      'Use nosso modelo Excel para enviar seus funcionários. A segunda guia da planilha fornecerá instruções e exemplos, se necessário. Assim que o seu arquivo Excel estiver concluído, use o botão Upload para enviá-lo. Você poderá verificar as informações de cada funcionário antes de importar.',
    description2:
      'O modelo inclui duas colunas na extrema direita para você adicionar dados demográficos personalizados do local de trabalho. Isso fornecerá opções adicionais de filtragem para seus relatórios.',
    downloadExcelTemplate: 'Clique aqui para baixar o modelo Excel.',
    dragAndDrop: 'Arraste e solte sua planilha ou clique aqui!',
    importingYourSpreadsheet:
      'Importando sua planilha! Isto pode levar algum tempo...',
  },
  invoices: {
    title: 'Faturas',
    description: 'Verifique e gerencie suas faturas aqui',
    invoicePDF: 'Clique aqui para baixar o PDF da fatura',
    proceedToPayment: 'Prossiga ao Pagamento',
    fields: {
      invoiceDate: 'Data da Fatura',
      receiptNumber: '# do Recibo',
      paid: 'Pago',
      amount: 'Valor',
    },
  },
  roles: {
    title: 'Responsabilidades',
    description:
      'Gerencie as configurações de permissão para cada responsabilidade em sua organização',
    types: {
      companyAdmin: 'Administrador',
      ceo: 'CEO',
      executive: 'Executivo',
      manager: 'Gestor',
      employee: 'Funcionário',
    },
    fields: {
      allowBeseenApplication: 'Permitir aplicação #BeSeen',
      permissions: 'Permissões',
      selectAll: 'Selecionar Todas',
    },
    validations: {
      typeIsRequired: 'Tipo é obrigatório',
      descriptionIsRequired: 'Descrição é obrigatória',
    },
  },
  demographics: { title: 'Demografia', description: 'Demografia descrição' },
  settings: {
    formLabels: {
      companyPurpose: 'Propósito da Organização',
      timezone: 'Fuso Horário',
      suggestionBox: 'Nome da Caixa de Sugestões',
    },
    placeholders: {
      companyPurpose: 'Qual é o propósito da sua organização?',
      timezone: 'Em que fuso horário você se encontra?',
      suggestionBox: 'Compartilhe suas sugestões incríveis!',
    },
    sectionTitles: {
      socialMedia: 'Redes Sociais',
      feed: 'Feed',
      insightsPermissions: 'Permissões de Insights',
      nominations: 'Nomeações',
      mfa: 'Autenticação Multifator',
      profanityFilter: 'Filtro de Profanidade',
      integrations: 'Integrações',
      recognitionFrequency: 'Frequência de Reconhecimento',
    },
    sectionControlLabels: {
      socialMedia: {
        allowSharing: 'Permitir Compartilhamento em Redes Sociais',
        facebook: 'Facebook',
        linkedIn: 'LinkedIn',
        twitter: 'Twitter',
      },
      feed: { suggestionBox: 'Incluir Caixa de Sugestões' },
      insightsPermissions: {
        organizationWideBeheard: 'Mostrar BeHeard de Toda a Organização',
        pulseSurvey: 'Mostrar Pesquisa Pulse',
        managerTeamBeheard: 'Mostrar BeHeard da Equipe dos Gerentes',
        managerTeamPulseSurvey: 'Mostrar Pesquisa Pulse da Equipe dos Gerentes',
      },
      profanityFilter: {
        enableProfanityFilter: 'Habilitar Filtro de Profanidade',
      },
      nominations: { enableNominations: 'Habilitar Nomeações' },
      mfa: {
        requireMFA:
          'Definir Autenticação Multifator como obrigatória para todos os funcionários',
      },
      recognitionFrequency: {
        atLeastEvery: 'Pelo menos a cada {frequency} dias',
      },
    },
    saveChanges: 'Salvar Alterações',
    suggestionBoxTooltipTitle:
      'Dê aos funcionários a oportunidade de compartilhar seus pensamentos e ideias.',
    slackIntegration: {
      step1: "Step #1: Set your Slack's Workspace ID",
      workspaceIdOfSlack: 'Workspace ID of your Slack Organization',
      setWorkspaceID: 'Set Workspace ID',
      step2:
        'Step #2: Click the link below and allow Sparck to set Sparcky Bot to your Slack Workspace',
      allowSparckToIntegrateSlack:
        "Allow Sparck to integrate with your Slack's Workspace",
      alreadyClickedSparckBot:
        "Already clicked the button above and finished the process? {link} to reload the screen and check Slack's integration status!",
    },
    msTeamsIntegration: {
      stepsTutorialFindLink: {
        stepOne: {
          label: 'Clique no botão de 3 pontos',
          description:
            'O botão de 3 pontos está localizado à direita no canal que você criou. Em seguida clique em "Obter link para o canal"',
        },
        stepTwo: { label: 'Clique em copiar!' },
      },
      stepsTutorialInstallBot: {
        stepOne: {
          label: 'Baixar Sparck Bot',
          customDescription: {
            partOne: 'Clique em "Baixar MS Teams Sparck Bot" nesta tela',
            partTwo:
              'Vá para o MS Teams > Aplicativos > Gerencie seus Apps > Faça o upload do seu app para o seu catálogo de aplicativos',
          },
        },
        stepTwo: {
          label: 'Instalar Aplicativo',
          description:
            'Encontre o Sparck Bot no seu explorador de arquivos, selecione e clique no App Sparck em MS Teams',
        },
        stepThree: {
          label: 'Adicionar à Equipe',
          description:
            'Clique no botão da seta para baixo e selecione "Adicionar a uma Equipe"',
        },
        stepFour: {
          label: 'Configurando',
          description:
            'Digite o nome da sua organização e selecione a opção com o canal Sparck Feed que você criou nos passos anteriores e clique "Configurar um Bot"',
        },
      },
      stepsTutorialSetPolicies: {
        stepOne: {
          label: 'Adicionando Políticas',
          customDescription: {
            partOne:
              'Acesse a página de Configuração de Política de Aplicativos do seu MS Teams:',
            partTwo: 'Clique no link "Global (Org-wide default)"',
            partThree: 'Na seção "Apps Instalados", clique em "Adicionar apps"',
            partFour:
              'Digite "Sparcky" no campo de busca e clique em "adicionar" para o app Sparcky',
          },
        },
        stepTwo: {
          label: 'Fixar App',
          customDescription: {
            partOne: 'Você verá o bot listado na seção "Apps Instalados"',
            partTwo:
              'Vá para a seção "Apps Fixados" e clique no link "+ Adicionar apps"',
            partThree:
              'Digite Sparcky no campo de busca, clique em "adicionar" para o app Sparcky e clique em "adicionar" novamente para salvar este passo',
            partFour:
              'Digite "Sparcky" no campo de busca e clique "adicionar" para o app Sparcky',
          },
        },
        stepThree: {
          label: 'Concluído!',
          customDescription: {
            partOne: 'Você verá o bot listado na seção "Apps fixados"',
            partTwo: 'Posicione o Sparcky onde desejar arrastando e soltando',
            partThree: 'Então clique no botão "Salvar" na parte inferior',
          },
        },
      },
      stepOne: 'Passo #1: Cole o link do seu Canal MS Teams',
      channelLinkLabel: 'Link do Canal da sua Organização MS Teams',
      save: 'Salvar',
      stepTwo:
        'Passo #2: Clique no link abaixo para baixar seu Sparck Bot (arquivo .zip)',
      downloadMSTeamsSparckBot: 'Baixar Sparck Bot para o MS Teams',
      frequentlyAskedQuestions: 'Perguntas Frequentes',
      whereToFindChannelLink: 'Onde encontro o link do  meu Canal MS Teams?',
      whatToDoNext: 'Baixei meu Sparck Bot. O que faço agora?',
      sparckBotByDefault:
        'Quero que os funcionários da minha organização tenham o Sparck Bot por padrão. Como faço isso?',
    },
    settingsIntegration: {
      sparckFeedChannelDefined: 'Canal Sparck Feed já definido',
      integrate: 'Integrar',
      remove: 'Remover',
      integrationSectionTitles: {
        slackBot: 'Integração do Slack Bot',
        msTeams: 'Integração do MS Teams Bot',
      },
      slackBotIntegrationDialogTitle: 'Integração do Slack Bot',
      msTeamsBotIntegrationDialogTitle: 'Integração do MS Teams Bot',
      slackIntegrationConfirmDelete: {
        title: 'Remover Integração do Sparcky Bot Slack',
        itemName: 'Integração do Sparcky Bot Slack ',
        actionLabel: 'remover',
      },
      msTeamsIntegrationConfirmDelete: {
        title: 'Remover Integração Sparcky Bot MS Teams',
        itemName: 'Integração do Sparcky Bot MS Teams',
        actionLabel: 'remover',
      },
    },
    snackbarSuccess: { saved: 'Configurações salvas!' },
  },
  signup: {
    createAccount: 'Criar Conta',
    secondaryActionButtonLabels: { backToLogin: 'Voltar para o Login' },
    description:
      'Estamos animados em te ajudar a lançar sua Pesquisa #BeHeard. Comece com a versão gratuita e faça upgrade depois.Por favor forneça as seguintes informações para criar sua conta organizacional gratuita. Não é necessário nenhum cartão de crédito.',
    confirmEmailDialog: {
      title: 'Quase lá!',
      content:
        'Antes de criar sua conta, precisamos fazer uma verificação rápida de email - parece tudo certo?',
      changeEmail: 'Alterar email',
      emailIsCorrect: 'Email está correto',
    },
    formLabels: {
      companyName: 'Nome da Organização',
      industry: 'Indústria',
      websiteUrl: 'URL do Website',
      phoneNumber: 'Telefone',
      firstName: 'Nome',
      lastName: 'Sobrenome',
      email: 'Email',
      password: 'Senha',
      sendEmail: 'Enviar e-mail notificando que a organização foi criada.',
    },
    termsAndConditionsLink: {
      firstLine: 'Ao clicar em Criar Conta, você concorda com os',
      secondLine: 'Termos e Condições Sparck',
    },
    validations: {
      sparckAdminSchema: {
        companyNameIsRequired: 'Nome da Organização é obrigatório',
        invalidUrlFormat: 'Formato de URL inválido',
        websiteUrlIsRequired: 'URL inválida',
        industryIsRequired: 'Indústria é obrigatória',
      },
      employeesSchema: {
        companyNameIsRequired: 'Nome da Organização é obrigatório',
        invalidUrlFormat: 'Formato de URL inválido',
        websiteUrlIsRequired: 'URL é obrigatória',
        industryIsRequired: 'Indústria é obrigatória',
        firstNameIsRequired: 'Primeiro nome é obrigatório',
        lastNameIsRequired: 'Sobrenome é obrigatório',
        phoneNumberInvalidFormat:
          'Deve ter o formato válido de número de telefone nos EUA',
        phoneNumberIsRequired: 'Número de Telefone é obrigatório',
        invalidEmailFormat: 'Formato de email inválido',
        emailIsRequired: 'Email é obrigatório',
        passwordCapitalLetter:
          'Senha deve incluir ao menos uma letra maiúscula.',
        passwordLowercaseLetter:
          'Senha deve incluir ao menos uma letra minúscula.',
        passwordNumber: 'Senha deve incluir ao menos um número.',
        passwordSpecialCharacter:
          'Senha deve incluir ao menos um caractere especial (+, #, !, ? or @).',
        passwordIsRequired: 'Senha é obrigatória',
      },
    },
    snackbar: {
      success: {
        company: 'Organização',
        createdSuccessfully: 'criada com sucesso!',
      },
    },
  },
  login: {
    snackbar: {
      success: {
        checkEmail:
          'Por favor, verifique seu email para obter mais instruções.',
        useNewPassword:
          'Por favor, use sua nova senha para acessar a aplicação.',
        passwordUpdated: 'Senha atualizada com sucesso!',
      },
    },
    changePassword: {
      title: 'Alterar Senha',
      formLabels: { currentPassword: 'Senha Atual', newPassword: 'Nova Senha' },
      changePasswordButton: 'Alterar Senha',
      backToLoginButton: 'Voltar para o login',
    },
    loginForm: {
      welcome: 'Bem-Vindo!',
      backupCodeInstruction:
        'Digite um dos códigos de recuperação que você salvou ao habilitar a Autenticação Multifator',
      labels: {
        password: 'Senha',
        verificationCode: 'Código de Verificação',
        useDifferentMFAMethod: 'Usar um método AMF diferente',
        backupCode: 'Digite o Código de Recuperação',
        authApp: 'Aplicativo de Autenticação',
        useBackupCode: 'Usar Código de Recuparação',
      },
      resendVerificationCodeTooltip:
        'Reenviar código de verificação ao seu telefone',
      useBackupCode: 'Usar um código de recuperação',
      dontHaveBackupCode: 'Não tenho um código de recuperação',
      loginButton: 'Entrar',
      forgotPasswordButton: 'Esqueci minha senha',
    },
    recoverPassword: {
      setPassword: 'Defina sua senha',
      forgotPassword: 'Esqueceu sua senha?',
      enterNewPassword: 'Digite sua nova senha.',
      formLabels: { password: 'Senha' },
      enterEmail: 'Digite seu endereço de email para recuperar sua senha.',
      setPasswordButton: 'Definir Senha',
      recoverPasswordButton: 'Recuperar Senha',
      backToLoginButton: 'Voltar para o login',
    },
  },
  mfa: {
    enable: 'Habilitar',
    disable: 'Desabilitar',
    viewOrDownload: 'Ver/Baixar',
    primary: 'PRIMÁRIO',
    makePrimary: 'Tornar Primário',
    gotIt: 'Entendi!',
    enterPassword: 'Enter Your Password',
    enterVerificationCode: 'Enter Verification Code',
    mfaFactors: {
      authApp: {
        title: 'Aplicativo de Autenticação',
        description:
          'Use um aplicativo de autenticação para gerar códigos de segurança únicos (mais seguro).',
        steps: {
          scanQRCode: 'Escaneie o Código QR',
          success: 'Você habilitou o Aplicativo de Autenticação com sucesso!',
        },
      },
      sms: {
        title: 'SMS',
        description:
          'Use seu telefone móvel para receber códigos de segurança.',
        steps: {
          smsSetup: 'Configuração do SMS',
          success: 'Você habilitou o SMS com sucesso!',
        },
      },
      email: {
        title: 'Email',
        description:
          'Use seu email para receber códigos de segurança (menos seguro).',
        steps: { success: 'Você habilitou o Email com sucesso!' },
      },
      backupCodes: {
        title: 'Códigos de Recuperação',
        description:
          'Use códigos de recuperação quando não conseguir utilizar outro método.',
        steps: { success: 'Seus Códigos de Recuperação' },
        actions: { download: 'Baixar', print: 'Imprimir', copy: 'Copiar' },
      },
      dialogTitle: {
        setup: 'AMF Configuração de {selectedFactor}',
        disable: 'AMF Desabilitar {selectedFactor}',
      },
      disableDialogContent:
        'Deseja realmente desabilitar {selectedFactor} como método de autenticação multifator?',
    },
    enterCredentials: 'Para continuar, por favor digite sua senha',
    enterVerificationCodeMessage: {
      email: 'Enviamos um código de verificação para o e-mail: {email}.',
      sms: 'Enviamos um código de verificação via SMS para o número com final {phoneNumberLastDigits}.',
      authenticatorApp:
        'Digite o código de verificação gerado pelo seu aplicativo de autenticação.',
    },
    setupComponentTitle: 'Selecione opções de autenticação',
    setupComponentDescription:
      'Toda vez que você fizer login na sua conta Sparck, você precisará de sua senha e um código de verificação.',
    snackbars: {
      success: {
        mfaDisabled:
          '{selectedFactor} foi desabilitado como método de AMF com sucesso!',
      },
      error: {
        verificationCode:
          'Não conseguimos verificar o código que você digitou. Certifique-se de fornecer um código válido',
      },
    },
    next: 'Próximo',
    youNeedAnAuthApp:
      'Você precisará de um app de autenticação como o {example} para completar este processo',
    scanQRCode: 'Use seu aplicativo para escanear o código QR',
    whenYouAreDone: "Quando terminar de escanear, clique em 'Próximo'",
    successfullyEnabled: 'Habilitada com sucesso',
    yourBackupCodes: 'Seus códigos de recuperação',
    yourBackupCodesDescription:
      'Guarde estes códigos de recuperação em um lugar seguro. Se você perder seu telefone ou dispositivo para autenticação, você poderá utilizar códigos de recuperação para se autenticar.',
    footerMessage:
      'Os códigos de autenticação serão enviados para o número que você forneceu.',
    validations: {
      verificationCodeRequired: 'O código de verificação é obrigatório',
    },
    enterSMSCode: 'Digite o código de 6 dígitos que te enviamos via SMS',
    enterAuthAppCode: 'Digite o código de 6 dígitos gerado pelo seu aplicativo',
    enterEmailCode:
      'Digite o código de 6 dígitos que enviamos para o seu email',
    labels: { enterYourCode: 'Digite seu código' },
    resendCode: 'Reenviar Código',
    enterPhoneNumber:
      'Por favor, digite o número que deseja usar para receber os códigos de verificação.',
    mfaOptions: {
      authApp: 'App de Autenticação',
      sms: 'Mensagem de Texto (SMS)',
    },
    weRecommendAnApp:
      'Recomendamos um aplicativo baseado em tempo para manter nossos clientes seguros.',
    mfaRequired:
      '{organizationName} definiu Autenticação Multifator (MFA) como obrigatória',
    mfaRequiredDescription:
      '{organizationName} requer de cada funcionário a habilitação de Autenticação Multifator para uso com a aplicação Sparck. Isto adiciona uma camada extra de segurança e proteção para os seus dados e os dados da sua empresa e é muito fácil de configurar. Basta selecionar o método que você deseja usar abaixo e nós te mostraremos como realizar a configuração. Se decidir usar um método diferente no futuro, você poderá atualizar suas configurações de MFA pela tela do seu Perfil.',
    startMFASetup: 'Iniciar Configuração de MFA',
    takeMeToSparck: 'Leve-me para o Sparck!',
    backupCodesDialog: {
      title: 'Seus códigos de recuperação',
      resetSuccessAlert: {
        title: 'Sucesso!',
        description:
          'Seus códigos de recuperação acabaram de ser redefinidos com sucesso.',
      },
      resetBackupCodes: 'Redefinir Códigos de Recuperação',
      submit: 'Enviar',
    },
  },
  marketing: {
    snackbar: {
      success: {
        surveyAddedToCart: 'Pesquisa adicionada ao seu carrinho com sucesso',
      },
    },
    testimonialFlexCare:
      'Na FlexCare, nossa cultura organizacional é muito importante para nós porque nossos funcionários são o coração dos nossos negócios. À medida que começamos a crescer, o Relatório #BeHeard Premium do Sparck nos ajudou a alinhar nossas prioridades com base em nossa eficiência e no que é mais importante para nossos funcionários. Conseguimos delinear uma estratégia a nível geral da organização e ao mesmo tempo personalizando os resultados usando a localização e os departamentos enquanto mantivemos o anonimato dos colaboradores. Isto serviu como uma ótima ferramenta de coaching que forneceu a nossos gerentes uma melhor perspectiva de como liderar uma equipe mais engajada e mais produtiva. Eu recomendo muito esta pesquisa. Foi um processo fácil com perspectivas de ações!',
    testimonialTeledyneTechnologies:
      'A ferramenta de Análise de Custo de Engajamento de Funcionários fornecida pelo Sparck nos deu uma maneira fácil e eficaz de comunicar como o aumento no engajamento dos funcionários impactaria diretamente os resultados da nossa organização. A visão geral customizada de uma página foi um grande acréscimo à nossa reunião executiva e nos deu uma maneira de ver nossos números específicos e delinear uma solução. Recomendo altamente esta ferramenta para profissionais de RH que estão mapeando suas estratégias internas  e querem fazer um upgrade para o Relatório #BeHeard Premium.',
    upgradeNow: 'Fazer Upgrade Agora',
    marketingHeader: {
      upgradeToday: 'Faça Upgrade Hoje!',
      getMoreStrategicLook:
        'Obtenha uma visão mais estratégica dos seus resultados da Pesquisa #BeHeard com recomendações personalizadas e perspectivas demográficas.',
      beheardPremiumReportDescription:
        'Nosso Relatório #BeHeard Premium estatisticamente válido fornecerá um entendimento mais profundo de seus resultados e passos para tomada de ações para aumentar o engajamento de seus funcionários em cada nível da sua organização. Além disso, você também receberá percepções críticas e conselhos específicos quando fizer upgrade para o Relatório Premium. Verifique os detalhes abaixo, e se quiser conversar, estaremos aqui.',
      upgradeNow: 'Fazer Upgrade Agora',
    },
  },
  shoppingCart: {
    yourShoppingCartItems: 'Seus Itens no Carrinho',
    employee: 'funcionário',
    employees: 'funcionários',
    removeFromCart: 'Remover do Carrinho',
    proceedToPayment: 'Prossiga para o Pagamento',
    noItems: 'Você não tem itens no seu carrinho de compras.',
    productTitle:
      '{productName} - {itemToUpgradeName} para {employeesQuantity}',
    total: 'Total',
    snackbar: {
      success: { itemRemoved: 'Item removido do seu carrinho com sucesso' },
      error: {
        couldNotRemoveItem:
          'Não foi possível remover este item do seu carrinho',
      },
    },
  },
  notifications: {
    survey: {
      isNowOpened: '{surveyName} está aberta!',
      isClosed: '{surveyName} foi encerrada!',
      isReopened: '{surveyName} foi reaberta!',
      wasExtended: '{surveyName} foi estendida!',
      canNowBeUpgraded: '{surveyName} pode receber upgrade a partir de agora!',
      needsToBeFinished:
        'Você tem uma {surveyName} pesquisa que precisa ser concluída!',
      lastDayToFinish: 'Hoje é o último dia para concluir {surveyName}!',
      thankYou: 'Muito obrigado por sua participação na pesquisa {surveyName}!',
      fullReportAvailable:
        'Parabéns! Seu Relatório #BeHeard Completo para {surveyName} já está disponível!',
      freeReportAvailable:
        'Seu Relatório #BeHeard para {surveyName} já está disponível!',
    },
    pulseSurvey: {
      isNowOpened: '{pulseSurveyName} está aberta!',
      isClosed: '{pulseSurveyName} foi encerrada!',
      isReopened: '{pulseSurveyName} foi reaberta!',
      wasExtended: '{pulseSurveyName} foi estendida!',
      needsToBeFinished:
        'Você tem uma pesquisa pulse {pulseSurveyName} que precisa ser concluída!',
      lastDayToFinish: 'Hoje é o último dia para concluir {pulseSurveyName}!',
      thankYou:
        'Muito obrigado por sua participação na pesquisa pulse {pulseSurveyName}!',
    },
    sparck: {
      youRecognized: 'Você reconheceu {sparckedUser}!',
      recognizedBy: 'Você foi reconhecido por {sparckOwner}!',
      takeActionForRecognition:
        'Tome uma ação a respeito desse reconhecimento de {sparckOwner} para {sparckedUser}!',
      wasAnswered: 'Seu Sparck foi respondido por {sparckedUser}:',
      rewardRedeemed: '{sparckedUser} resgatou seu benefício',
    },
    mentions: {
      title:
        'Você foi mencionado(a) por {mentioningUser} em um(a) {sourceType}!',
    },
    suggestions: {
      newReply: {
        title: 'Você recebeu uma nova {newReply} relacionada a uma sugestão',
        newReplyLabel: 'nova resposta',
      },
      newSuggestion: { title: 'Há uma nova sugestão de um funcionário.' },
    },
    actionLabels: {
      viewSurvey: 'Ver Pesquisa',
      viewPulseSurvey: 'Ver Pesquisa Pulse',
      viewReport: 'Ver Relatório',
      viewSparck: 'Ver Sparck',
      takeAction: 'Tomar Ação',
      viewPost: 'Ver Publicação',
      viewComment: 'Ver Comentário',
      goToChat: 'Ir para o Chat',
      goToSuggestions: 'Ir para sugestões',
    },
    yourNotifications: 'Suas Notificações',
    markAllRead: 'Marcar todas como lidas',
    noNotifications: ' Você não tem nenhuma notificação.',
  },
  managerCertification: {
    managerCertificationData: {
      roadmap: {
        dayOne: {
          title: 'Bem-Vindo(a) à Certificação Sparck',
          description:
            'Bem-Vindo(a) à Seção 1 da Certificação de Reconhecimento de Funcionários Sparck. Para começar, vamos te levar à linha de largada da semana com uma introdução à Sprint de 3 Minutos Sparck. Para completar a Seção 1 com sucesso, você precisa assistir ao vídeo de treinamento e participar do nosso quiz diário. Divirta-se.',
          sections: {
            learnItDescription:
              'É aqui que a mágica começa. Nesta seção, você poderá acessar facilmente o vídeo de treinamento e todos os recursos que precisa para desenvolver estas novas habilidades.',
            masterItDescription:
              'Espero que tenha gostado do primeiro vídeo. É hora de testar seu conhecimento até aqui e colocar as áreas designadas pela Anna em ação. Mal podemos esperar para ver quem você vai decidir reconhecer.',
          },
          tasks: {
            trainingVideoName: 'Vide De Treinamento Da Seção 1',
            challengeName: 'Desafio Da Seção 1',
          },
        },
        dayTwo: {
          title: 'Minuto 1: Reflita - Tempo é Tudo',
          description:
            'Bem-Vindo(a) à Seção 2 da Certificação! Ontem fizemos uma introdução à Sprint de 3 Minutos Sparck e agora é hora de revelar as camadas. Hoje você vai se aprofundar no Minuto 1 da Sprint Sparck: Reflita. Para completar a Seção 2 com sucesso, assista ao vídeo de treinamento, faça o Sparck Tour e participe do quiz. Divirta-se!',
          sections: {
            practiceItDescription:
              'Esta é uma nova seção que será incorporada pelo resto da semana. A Anna vai usar o que você aprendeu no vídeo de treinamento e te mostrar como a tecnologia te ajuda a alavancar essas lições. Aperte os cintos, é hora de fazer o tour! ',
            masterItDescription:
              'É hora de usar o que você aprendeu e colocar à prova através do Quiz da Seção 2. Isso não é para te fazer errar, e sim para te ajudar a dominar cada minuto da Sprint. Certifique-se de estar logado em sua conta Sparck e começar o processo de reflexão com os insights da sua equipe.',
          },
          tasks: {
            trainingVideoName: 'Vídeo de Treinamento Da Seção 2',
            challengeName: 'Desafio Da Seção 2',
            challengeDescription:
              'Como sabe, a meta de reflexão é identificar quem, por que e quando você precisa reconhecer na sua equipe. Dê uma alavancada nos seus insights Sparck e ganhe uma visão de reconhecimento imediata.',
          },
        },
        dayThree: {
          title: 'Minuto 2: Conecte-se - Personalize A Experiência',
          description:
            'Bem-Vindo(a) à Seção 3 da Certificação! O dia de hoje focará no Minuto 2 da Sprint Sparck: Conecte-se. Para completar a Seção 3 com sucesso, assista o vídeo de treinamento, faça o Sparck Tour e participe do Quiz. Temos recursos como uma planilha dos diferentes Estilos de Apreciação e uma lista de perguntas F.R.O.G. (você saberá o que isso significa depois do treinamento). Esses recursos te ajudarão a aumentar e fortalecer as conversas com seus funcionários. Certifique-se de assistir o vídeo cômico também, é garantia de boas risadas!',
          sections: {
            masterItDescription:
              'Fortaleça seu conhecimento e certifique-se de participar do Quiz Da Seção 3. Mas não pare por aí, tendo em mente quem você planeja reconhecer no final da certificação, entre na sua conta e verifique o Estilo de Apreciação Sparck dessa pessoa!',
          },
          tasks: {
            trainingVideoName: 'Vídeo De Treinamento Da Seção 3',
            sparckAppreciationStyles: 'Estilos De Apreciação Sparck',
            sparckAppreciationStylesDescription:
              'Aprenda as diferentes maneiras que seus funcionários gostam de ser reconhecidos. Verifique os diferentes Estilos de Apreciação Sparck {link}. Para aprender mais sobre os diferentes Estilos de Apreciação (e rir muito), assista este vídeo cômico criado por Betsaida LeBron, estrategista de construção de equipes, coach de administração, e fundadora da ImprovEQ. Clique abaixo para assistir!',
            challengeName: 'Desafio Da Seção 3',
            challengeDescription:
              'Você acha que já conhece os estilos de apreciação da sua equipe? Descubra se está certo.',
          },
        },
        dayFour: {
          title: 'Minuto 3: Reconheça - Hora de Agir',
          description:
            'Bem-Vindo(a) à Seção 4 da Certificação! Hoje vamos focar no Minuto 3 da Sprint Sparck: Reconheça. Você está quase na linha de chegada! Agora que você já sabe como Refletir and Conectar-se, a peça final é aperfeiçoar o reconhecimento. Para completar a Seção 4 com sucesso, assista ao vídeo de treinamento, faça o Sparck Tour, e complete o Quiz. Não se esqueça de acessar os destaques nos seus Recursos de Treinamento. Divirta-se!',
          sections: {
            masterItDescription:
              'Você está muito perto, teste seu conhecimento através do Quiz da Seção 4. E depois de hoje, você deve estar curioso para descobrir quais recompensas são mais significativas para sua equipe. Faça login na sua conta e explore os perfis deles!',
          },
          tasks: {
            trainingVideoName: 'Treinamento Da Seção 4',
            challengeName: 'Desafio da Seção 4',
            challengeDescription:
              'Pense na pessoa que você vai reconhecer esta semana. Se você tivesse $10 para gastar, o que você daria a essa pessoa? Você está a alguns cliques de saber.',
          },
        },
        dayFive: {
          title: 'É Hora De Reconhecer Seu Funcionário!',
          description:
            'Bem-Vindo à Seção 5 da Certificação. Você está prestes a cruzar a linha de chegada! Use o que você aprendeu até aqui e combine as peças mágicas da Sprint de 3 Minutos Sparck: Conecte-se, Reflita, e Reconheça para criar uma experiência de reconhecimento significativa. Assista ao vídeo de treinamento, faça o Sparck Tour, e complete o quiz final. Para receber mais inspiração, verifique seus recursos de treinamento. {remembermessage}',
          rememberMessage:
            'E lembre-se: Além dos exercícios, para se tornar oficialmente certificado você precisa usar o que aprendeu e reconhecer um de seus empregados. Não podemos esperar para ouvir sua história.',
          sections: {
            masterItDescription:
              "Você conseguiu, espero que você tenha gostado da sprint! Cruze a linha de chegada com o Quiz da Seção 5 e compartilhe sua história 'Sparck'. Se você precisar de algumas ideias antes de agir, certifique-se de usar os insights que a Anna compartilhou durante o tour e acesse sua conta!",
          },
          tasks: {
            trainingVideoName: 'Treinamento Da Seção 5',
            challengeName: 'Desafio da Seção 5',
            challengeDescription:
              'Dê uma olhada nos bastidores e identifique como reconhecer todos os membros de sua equipe na Hora Certa, da Maneira Certa e com a Recompensa Certa! É hora de Sparck em seu local de trabalho.',
          },
        },
        trainingResources: 'Acessar Recursos de Treinamento',
        takeTheQuiz: 'Participar do Quiz',
        watchSparckTour: 'Assistir ao Tour Sparck',
      },
      learnIt: 'Aprenda',
      practiceIt: 'Coloque em Prática',
      masterIt: 'Domine',
    },
    celebrationBoard: {
      title: 'Vamos Comemorar Sua Certificação!',
      description:
        'Comemore conosco! É sempre mais divertido compartilhar seu entusiasmo com os outros e por isso todos que finalizarem a Certificação terão seus nomes adicionados ao painel. Mal podemos esperar para ver o lindo impacto que você fará na sua organização. Vamos iluminar o mundo através do Sparck!',
      congratulations: 'Parabéns!',
    },
    leaderboard: {
      title: 'Divirta-se e veja sua posição na Tabela de Classificação!',
      header: 'Classificação',
    },
    managerCertificationComponent: {
      minutes: {
        minuteOneDescription:
          'O Minuto 1 te ensina a Refletir e aproveitar oportunidades de reconhecimento no Momento Certo. Tempo é tudo.!',
        minuteTwoDescription:
          'O Minuto 2 te ensina a Conectar e identificar o Momento Certo para reconhecer. É essencial aprender as preferências dos seus funcionários.',
        minuteThreeDescription:
          'O Minuto 3 te ensina a Reconhecer com a Recompensa Certa. Personalização é a chave, não seja genérico.',
      },
      secretComponents: {
        rightTime: 'Momento Certo',
        rightWay: 'Maneira Correta',
        rightReward: 'Recompensa Certa',
      },
      sections: {
        welcome: {
          title: 'Certificação de Reconhecimento de Funcionários',
          description: {
            firstParagraph:
              'Bem-Vindo, pronto para se divertir? Nesta certificação você irá aprender como incorporar nossa Sprint de 3 Minutos Sparck em seu dia. Foi preparada para equipar você para realizar reconhecimento personalizado eficiente. Isso aumentará não só a felicidade de seus funcionários como também a produtividade geral e o comprometimento. Esta certificação foi criada para ser concluída em um período de 5 dias, mas sinta-se à vontade para se aprofundar e concluir tudo em seu próprio ritmo!',
            middleParagraph:
              'Nossa Sprint de 3 Minutos Sparck vai te preparar para realizar o tipo de reconhecimento certo, todas as vezes.',
            lastParagraph:
              'Há uma arte no ato de reconhecer que nunca foi ensinada, estes são os aspectos para os quais você estará pronto:',
          },
        },
        realLifeSparckStory: {
          title: 'Uma História Real Sparck',
          subtitle:
            'Quer ver o impacto que um reconhecimento personalizado pode causar?',
          description:
            'Assista como a Sprint de 3 Minutos Sparck capacitou nossa CEO, Anna Straus, para reconhecer Abigail Kidd no Momento Certo, da Maneira Correta, com a Recompensa Certa. Esta história será analisada durante  toda a certificação para dar vida ao treinamento!',
        },
        sneakPeek: {
          title: 'Veja Uma Introdução Da Certificação Sparck',
          description:
            'Você está prestes a embarcar em uma jornada emocionante com a nossa Certificação de Reconhecimento de Funcionários! Veja uma introdução do que vem pela frente onde nossa CEO Anna Straus guia você através da Sprint de 3 Minutos Sparck!',
        },
        roadmap: {
          title: 'Mapa da Certificação',
          description:
            'Com apenas 10 minutos por dia, ao final de um período de cinco dias você estará certificado pelo programa de Reconhecimento de Funcionários Sparck. Este kit de recursos irá mostrar tudo que você fará em cada dia para que você tenha êxito ao passar pelo programa. Veja o que te espera e acesse o Mapa de 5 Seções clicando nos cartões abaixo.',
          enter: 'ACESSAR',
        },
      },
    },
    submitStoryCard: {
      title: 'Conte Sua História',
      description:
        'Compartilhe todos os detalhes emocionantes conosco enquanto celebramos sua corrida pela linha de chegada! Se você tem uma história de uma vez que alguém no trabalho fez você se sentir verdadeiramente visto, você pode compartilhar essa história também!',
      submitStoryForm: {
        snackbarSuccess: 'Sua história foi enviada com sucesso',
        tellYourStory: 'Conte sua história',
        permissionToShareOnSocialMedia:
          'Você nos permite compartilhar seu reconhecimento nas redes sociais?',
        canWeTagYouOnSocialMedia:
          'Podemos marcar você? Se sim, por favor informe os dados de suas redes sociais. (IG, Twitter, e LinkedIn)',
      },
    },
    managerCertificationTask: {
      day: 'Seção',
      clickForResource: 'Clique {link} para baixar {name}',
      trainingResourceForSection:
        'Recurso de Treinamento para a Seção {section}',
      clickForVideoScript: 'Click {link} for the video script',
      frogQuestions: 'perguntas F.R.O.G.',
      takeTheQuiz: 'Faça o Quiz!',
      takeMeToTheChallenge: 'Leve-me ao desafio!',
    },
    managerCertificationTasks: {
      trainingVideo: 'vídeo de treinamento',
      trainingVideos: 'vídeos de treinamento',
      trainingResource: 'recurso de treinamento',
      trainingResources: 'recursos de treinamento',
      quiz: 'quiz',
      quizzes: 'quizzes',
      challenge: 'desafio',
      challenges: 'desafios',
    },
    managerCertificationFormBuilder: {
      termsOfCommunicationFirstParagraph:
        'Sparck tem um compromisso com a proteção e o respeito à sua privacidade, e somente usaremos seus dados pessoais para administrar sua conta e fornecer os produtos e serviços que você solicitar. De tempos em tempos, gostaríamos de entrar em contato com você sobre nossos produtos e serviços, bem como outros conteúdos que possam ser de seu interesse. Se concordar, por favor marque a caixa abaixo para informar como gostaria que entrássemos em contato.',
      termsOfCommunicationMiddleParagraph:
        'Você pode cancelar o recebimento dessas comunicações a qualquer momento. Para mais informações a respeito de como cancelar, nossas práticas de privacidade e como somos comprometidos com a proteção e respeito à sua privacidade, por favor, revise nossa Política de Privacidade.',
      termsOfCommunicationLastParagraph:
        'Ao clicar no botão de enviar abaixo, você permite ao Sparck armazenar e processar a informação enviada acima para fornecer a você o conteúdo solicitado.',
      agreeToReceiveCommunications:
        'Eu concordo em receber outras comunicações do Sparck',
    },
    managerCertificationQuizDialog: {
      title: 'Quiz da seção {section}',
      requiredField: 'Por favor, preencha este campo obrigatório',
      snackbarSuccess: 'Suas respostas foram enviadas com sucesso!',
      quizQuestions: {
        sectionOne: {
          whoAreYouGoingToRecognize:
            'Quem você irá reconhecer no fim desta certificação?',
          runningBuddy:
            'Quem você escolheu como seu "companheiro de jornada" (opcional)?',
          threeComponentsOfThreeMinuteSparckSprint:
            'Quais são os três componentes da Sprint de três minutos Sparck?',
          threeAreasToDeliverEffectiveRecognition:
            'As três áreas que você precisa considerar para realizar um reconhecimento eficiente são No Momento Certo, Da Maneira Certa, e com a ____ Certa.',
          feedbackOrSuggestionsSection1:
            'Algum feedback, sugestão ou ideias que você deseja compartilhar sobre o treinamento? (opcional)',
        },
        sectionTwo: {
          didThePersonChange:
            'A pessoa que você escolheu para reconhecer mudou com base neste tempo de reflexão? Se sim, para quem?',
          goalDuringYourReflection:
            'O objetivo durante sua reflexão é identificar Quem, Por quê e ______ reconhecer alguém.',
          feedbackOrSuggestionsSection2:
            'Alguma ideia, sugestão ou feedback que você deseja compartilhar para tornar este treinamento mais proveitoso? (opcional)',
        },
        sectionThree: {
          gInTheFrogStandsFor:
            'O que significa o "G" no modelo conversacional F.R.O.G.?',
          yourSparckAppreciationStyle:
            'Qual é o seu Estilo de Apreciação Sparck? Com o que você concorda e o que você mudaria?',
          appreciationStyleOfTheEmployeeYouAreGoingToRecognize:
            'Qual é o Estilo de Apreciação Sparck do funcionário que você vai reconhecer?',
          feedbackOrSuggestionsSection3:
            'Você gostaria de compartilhar algum feedback, sugestão ou ideia para melhorar este treinamento? (opcional)',
        },
        sectionFour: {
          howWouldYouDescribeTheTwoTypesOrRewards:
            'Depois deste treinamento, como você descreveria os dois tipos de recompensa que você pode oferecer?',
          recognitionNeedsToBeFrequentSpecificAnd:
            'Reconhecimento é um reforço positivo que precisa ser frequente, específico e _________.',
          whatAreYouRecognizingThemForSection4:
            'Pense na pessoa que você vai reconhecer. Por qual motivo você a estará reconhecendo?',
          feedbackOrSuggestionsSection4:
            'Você gostaria de compartilhar algum feedback, sugestão ou ideia para melhorar este treinamento?',
        },
        sectionFive: {
          mostValuableThingYouLearnedThisWeek:
            'Qual a coisa mais valiosa que você aprendeu esta semana?',
          whatWouldYouChangeAboutTheCertification:
            'O que você mudaria a respeito desta certificação?',
          whatDayAreYouGoingToRecognizeThem:
            'Reflita: Agora que você sabe quem você vai reconhecer, que dia você irá reconhecê-lo(a)?',
          howAreYouGoingToRecognizeThem:
            'Conecte-se: Agora que você conhece as preferências de reconhecimento, como você irá reconhecer a(s) pessoa(s)?',
          whatAreYouRecognizingThemForSection5:
            'Reconheça: Agora que você sabe que suas palavras devem ser genuínas e específicas, por que motivo você irá reconhecer a(s) pessoa(s)',
          whatIsYourRecognitionPreference:
            'Adoramos comemorar com aqueles que já estão certificados. Quais é sua preferência de reconhecimento?',
          canWeTagYouInSocialMedia:
            'Podemos te marcar em uma publicação em nossas redes sociais quando você concluir? (Digite Sim ou Não)',
          testimonial: {
            description:
              'Olá, Estrela-Sparck! Você está no caminho para se tornar certificado. Se você sentir que este treinamento tem te agregado algum valor e que tem um testemunho que podemos compartilhar com outros gerentes, adoraríamos ouví-lo!',
            label: 'Seu Testemunho',
          },
        },
      },
    },
    managerCertification5DayRoadmap: {
      title: 'Mapa da Certificação de 5 Dias',
      description:
        'Este kit de ferramentas foi desenvolvido para um período de 5 dias, mas você não precisa esperar! Você pode concluir o treinamento em seu próprio ritmo. Ele mostrará cada dia à medida que você avança no programa para te fornecer tudo que precisa para obter sucesso. Veja o que te aguarda e acesse o Mapa de 5 Dias clicando no botão abaixo. Você poderá ler mais sobre estas histórias à medida que se aproximar da conclusão da certificação.',
      downloadRoadmap: 'Baixar o mapa',
    },
    submit: 'ENVIAR',
    noOneHasCompletedTheCertification:
      'Nenhum usuário concluiu a certificação até o momento.',
  },
  mobile: {
    feed: {
      floatingButton: {
        dialogTitle: 'Sobre Nós'
      }
    },
  }
}
